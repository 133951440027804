import React, { useState, useEffect, useContext } from "react";
import shortid from "shortid";
import Switch from "@mui/material/Switch";
import { Button, FormGroup, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgressbar } from "react-circular-progressbar";
import firebase, { db } from "../../utils/firebase";
import "react-circular-progressbar/dist/styles.css";
import { UserContext } from "../../context/userContext";

const PilotRow = ({
  agent,
  deleteAgent,
  editPilot,
  resetPassword,
  i,
  viewDocuments,
}) => {
  const { user } = useContext(UserContext);
  const [percentage, setPercentage] = useState(0);
  const [isDeactivate, setIsDeactivate] = useState(false);
  useEffect(() => {
    let count = 0;

    agent.documents.map((doc) => {
      if (doc.url !== "") {
        count++;
      }
    });
    let percent = Math.ceil((count * 100) / 11);
    setPercentage(percent);
    setIsDeactivate(agent.accountStatus);
  }, []);

  const handleChange = async (id) => {
    await db.collection("pilots").doc(id).update({
      accountStatus: !isDeactivate,
    });
    setIsDeactivate(!isDeactivate);
  };
  return (
    <TableRow key={shortid.generate()}>
      <TableCell align="center">
        {i + 1}
      </TableCell>
      <TableCell align="left">
        {agent.name}
      </TableCell>
      <TableCell align="left">
        {agent.email}
      </TableCell>
      <TableCell align="left">
        {agent.contact}
      </TableCell>
      <TableCell align="left">
        <div style={{ width: 50, height: 50, marginLeft: 40, marginRight: 40 }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      </TableCell>

      {user.role !== "pilot" && (
        <TableCell className="px-0" align="left">
          <Switch
            checked={isDeactivate}
            onChange={() => handleChange(agent.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
      )}

      <TableCell className="px-0 border-none">
        <EditIcon
          style={{ cursor: "pointer" }}
          className="mx-4"
          color="primary"
          onClick={() => editPilot(agent)}
        />
        {user.role !== 'pilot' && <DeleteIcon
          style={{ cursor: "pointer" }}
          className="m-4"
          color="secondary"
          onClick={() => deleteAgent(agent)}
        />}
        <div>
          <Button
            style={{ fontSize: 12, marginLeft: 10, marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => resetPassword(agent)}
          >
            Reset Password
          </Button>
          <Button
            style={{ fontSize: 12, marginLeft: 10, marginTop: 10 }}
            variant="contained"
            color="secondary"
            onClick={() => viewDocuments(agent.documents)}
          >
            View Documents
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PilotRow;
