import { Route, Routes } from "react-router-dom";
import React, { useContext } from "react";
import Home from "../Pages/Home";
import CreateAdmin from "../Pages/CreateAdmin";
import Admins from "../Pages/Admins";
import AddPilot from "../Pages/AddPilot";
import Pilots from "../Pages/Pilots";
import AddCabinCrew from "../Pages/AddCabinCrew";
import CabinCrews from "../Pages/CabinCrews";
import AddTechRecord from "../Pages/AddTechRecord";
import TechRecords from "../Pages/TechRecords";
import AddAirport from "../Pages/AddAirport";
import Airports from "../Pages/Airports";
import AddEngineer from "../Pages/AddEngineer";
import Engineers from "../Pages/Engineers";
import AircraftTypes from "../Pages/AircraftTypes";
import AddAircraftType from "../Pages/AddAircraftType";
import AddAircraft from "../Pages/AddAircraft";
import Aircrafts from "../Pages/Aircrafts";
import AddAircraftLog from "../Pages/AddAircraftLog";
import AircraftLogs from "../Pages/AircraftLogs";
import AircraftLogDetail from "../Pages/AircraftLogDetail";
import ApprochPlates from "../Pages/ApprochPlates";
import AddApprochPlate from "../Pages/AddApprochPlate";
import { UserContext } from "../context/userContext";
// import Orders from "../Pages/Orders";
// import CreateOrder from "../Pages/CreateOrder";
// import Agents from "../Pages/Agents";
// import Feedbacks from "../Pages/Feedbacks";
// import AddFeedback from "../Pages/AddFeedback";
// import Tickets from "../Pages/Tickets";
// import EditFeedback from "../Pages/EditFeedback";

function LoggedInRouter() {
  const { user } = useContext(UserContext);
  
  return (
    <>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/createAdmin" element={<CreateAdmin />} />
          <Route exact path="/admins" element={<Admins />} />
          <Route exact path="/addAircraftTypes" element={<AddAircraftType />} />
          <Route exact path="/addAircraft" element={<AddAircraft />} />
          <Route exact path="/addAircraftLog" element={<AddAircraftLog />} />
          <Route exact path="/aircraftLogs" element={<AircraftLogs />} />
          <Route
            exact
            path="/aircraftLogDetail"
            element={<AircraftLogDetail />}
          />

          <Route exact path="/aircrafts" element={<Aircrafts />} />
          <Route exact path="/aircraftTypes" element={<AircraftTypes />} />
          <Route exact path="/addEngineer" element={<AddEngineer />} />
          <Route exact path="/engineers" element={<Engineers />} />
          <Route exact path="/addAirport" element={<AddAirport />} />
          <Route exact path="/airports" element={<Airports />} />

          
            <Route exact path="/addPilot" element={<AddPilot />} />
          

          <Route exact path="/pilots" element={<Pilots />} />
          <Route exact path="/addcabinCrew" element={<AddCabinCrew />} />
          <Route exact path="/cabinCrews" element={<CabinCrews />} />
          <Route exact path="/addTechRecord" element={<AddTechRecord />} />
          <Route exact path="/techRecords" element={<TechRecords />} />
          <Route exact path="/approachPlates" element={<ApprochPlates />} />
          <Route exact path="/addApprochPlate" element={<AddApprochPlate />} />
          {/*<Route exact path="/createOrder" element={<CreateOrder />} />*/}
          {/*<Route exact path="/addFeedback" element={<AddFeedback />} />*/}
          {/*<Route exact path="/editFeedback/:id" element={<EditFeedback />} />*/}
          {/*<Route exact path="/feedbacks" element={<Feedbacks />} />*/}
          {/*<Route exact path="/tickets" element={<Tickets />} />*/}
          {/*<Route exact path="/agents" element={<Agents />} />*/}
          <Route path="*" exact={true} element={<Home />} />
        </Routes>
      </div>
    </>
  );
}

export default LoggedInRouter;
