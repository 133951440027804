import React from "react";
import shortid from "shortid";
import {TableCell, TableRow} from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AircraftTypeRow = ({ agent, deleteAgent, editAircraft, i }) => {
    return (
        <TableRow key={shortid.generate()}>
            <TableCell align="left">
                {i+1}
            </TableCell>
            <TableCell align="left">
                {agent.id}
            </TableCell>
            <TableCell align="left">
                {agent.name}
            </TableCell>
            <TableCell className="px-0 border-none">
                <EditIcon style={{ cursor: 'pointer' }} color='primary' onClick={() => editAircraft(agent)}/>
                <DeleteIcon style={{ cursor: 'pointer' }} className='mx-4' color='secondary' onClick={() => deleteAgent(agent)} />
            </TableCell>
        </TableRow>
    );
};

export default AircraftTypeRow;
