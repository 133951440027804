import React, {useEffect, useState} from "react";
import shortid from "shortid";
import {Button, TableCell, TableRow} from "@material-ui/core";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../utils/firebase";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AirportRow = ({ agent, deleteAgent, editAircraft, viewAircraftLogDetails, i }) => {
    const [aircrafts, setAircrafts] = useState([]);
    const [pilots, setPilots] = useState([]);
    const [engineers, setEngineers] = useState([]);
    const [airports, setAirports] = useState([]);
    const [cabinCrews, setCabinCrews] = useState([]);
    const [load, setLoad] = useState(true);
    useEffect(() => {
        (async () => {
            const ac = []
            const p = []
            const ap = []
            const cc = []
            const e = []
            const acSnapshot = await getDocs(collection(db, "aircrafts"));
            acSnapshot.forEach((doc) => {
                ac.push(doc.data())
            });
            const pSnapshot = await getDocs(collection(db, "pilots"));
            pSnapshot.forEach((doc) => {
                p.push(doc.data())
            });
            const apSnapshot = await getDocs(collection(db, "airports"));
            apSnapshot.forEach((doc) => {
                ap.push(doc.data())
            });
            const ccSnapshot = await getDocs(collection(db, "cabinCrews"));
            ccSnapshot.forEach((doc) => {
                cc.push(doc.data())
            });
            const eSnapshot = await getDocs(collection(db, "engineers"));
            eSnapshot.forEach((doc) => {
                e.push(doc.data())
            });
            setAircrafts(ac)
            setAirports(ap)
            setPilots(p)
            setCabinCrews(cc)
            setEngineers(e)
            setLoad(false)
        })()
    }, [])

    const arr = agent.cabinCrew.map(cc => cabinCrews.filter(c => c.id === cc)[0]?.name)
    if (!load)
    return (
        <>
        <TableRow key={shortid.generate()}>
            <TableCell align="left">
                {i+1}
            </TableCell>
            <TableCell align="left">
                {agent.date}
            </TableCell>
            <TableCell align="left">
                {aircrafts.filter(a => a.id === agent.aircraft)[0]?.registration}
            </TableCell>
            <TableCell align="left">
                {pilots.filter(a => a.id === agent.captain)[0]?.name}
            </TableCell>
            <TableCell align="left">
                {pilots.filter(a => a.id === agent.coPilot)[0]?.name}
            </TableCell>
            <TableCell className="flex-col px-0" align="left">
                {arr.map(a => <p>{a}</p>)}
            </TableCell>
            <TableCell align="left">
                {engineers.filter(a => a.id === agent.engineer)[0]?.name}
            </TableCell>
            <TableCell align="left">
                {airports.filter(a => a.id === agent.fromAirport)[0]?.name}
            </TableCell>
            <TableCell className="px-0 border-none">
                <Button onClick={() => viewAircraftLogDetails(agent)} color='primary'
                        variant='contained'>
                    View
                </Button>
            </TableCell>
            <TableCell className="px-0 border-none justify-center items-center">
                <EditIcon style={{ cursor: 'pointer' }} color='primary' onClick={() => editAircraft(agent)}/>
                <DeleteIcon style={{ cursor: 'pointer' }} className='ml-3' color='secondary' onClick={() => deleteAgent(agent)} />
            </TableCell>
        </TableRow>
        </>
    );
    else return null
};

export default AirportRow;
