import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import { db } from "../utils/firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import PlatesRow from '../components/ApprochPlates/PlatesRow';
import { Modal } from "antd";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ViewPilotDocuments from "../components/Pilots/ViewPilotDocuments";
import PlatesDocuments from "../components/ApprochPlates/PlatesDocuments";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Pilots = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [agent, setAgent] = useState();
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const pilotRef = useRef();

  const fetchAgents = useCallback(async () => {
    setLoading(true);
    let admins = [];
    const querySnapshot = await getDocs(collection(db, "approachPlates"));
    querySnapshot.forEach((doc) => {
      admins.push(doc.data());
    });
    setData(admins);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const editPilot = (plates) => {
    navigate("/addApprochPlate", { state: { plates } });
  };

  const handleOk = async () => {
    await deleteDoc(doc(db, "approachPlates", agent.id));
    setIsModalVisible(false);
    await fetchAgents();
  };

  const deleteAgent = async (agent) => {
    setAgent(agent);
    setIsModalVisible(true);
  };


  const handleClose = () => {
    setOpen(false);
    setIsError(true);
  };

  const viewDocuments = (documents) => {
    pilotRef.current?.open({ documents });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading && <CircularProgress className="align-middle" />}
        {!loading && (
          <>
            <div className="m-4 text-2xl">Approch Plates Table</div>
            {!loading && data.length > 0 && (
              <Table
                className="crud-table"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  width: "100%",
                
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Airport Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((agent, index) => (
                    <PlatesRow
                      viewDocuments={viewDocuments}
                      i={index}
                      editPilot={editPilot}
                      deleteAgent={deleteAgent}
                      agent={agent}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </div>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(!isModalVisible)}
      >
        Are you sure you want to delete {agent?.name}?
      </Modal>
      
      <PlatesDocuments ref={pilotRef} />
    </>
  );
};

export default Pilots;
