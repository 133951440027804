import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useImperativeHandle, useState } from "react";
import { Divider } from "@mui/material";
import shortid from "shortid";

const ViewPilotDocuments = React.forwardRef((props, ref) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [documents, setDocuments] = useState([]);
  useImperativeHandle(ref, () => ({
    open: async (documents) => {
      if (documents.documents) {
        setDocuments(documents.documents);
        setViewDetails(true);
      }
    },
    close: () => {
      setViewDetails(false);
    },
  }));

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  if (documents)
    return (
      <Dialog
        fullScreen
        style={{ margin: 40 }}
        open={viewDetails}
        onClose={() => setViewDetails(false)}
        aria-labelledby="simple-dialog-title"
        className="address_modal"
      >
        <DialogTitle className="primary">{"View Documents"}</DialogTitle>
        <Divider />
        <DialogContent className="mt-5">
          <Table
            className="crud-table"
            style={{ whiteSpace: "pre", minWidth: "750px", width: "100%" }}
          >
            <TableHead>
              <TableRow style={{ background: "#F1F2F3" }}>
                <TableCell>Document Name</TableCell>
                <TableCell>Expiration Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((d) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {d.name}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {d.expiryDate}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {d.url === "" ? (
                      <p>Not Uploaded</p>
                    ) : (
                      <Button
                        style={{ marginBottom: 5 }}
                        onClick={() => openInNewTab(d.url)}
                        color="secondary"
                        variant="contained"
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDetails(false)} className="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  else return null;
});

export default ViewPilotDocuments;
