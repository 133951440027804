import React, { useContext, useState } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dreamer from "../assets/dreamer.svg";
import "../styles/login.css";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../utils/firebase";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = () => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(UserContext);
  const [passVisibility, setPassVisibility] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (email && password) {
      const auth = getAuth();
      // createUserWithEmailAndPassword(auth, email, password)
      //     .then((userCredential) => {
      //         // Signed in
      //         const user = userCredential.user;
      //         db.collection("superadmins").add({
      //             email,
      //         })
      //             .then((docRef) => {
      //                 console.log("Document written with ID: ", docRef.id);
      //                 db.collection("superadmins").doc(docRef.id).update({
      //                     id: docRef.id,
      //                 })
      //             })
      //             .catch((error) => {
      //                 console.error("Error adding document: ", error);
      //             });
      //         // ...
      //     })
      //     .catch((error) => {
      //         const errorCode = error.code;
      //         const errorMessage = error.message;
      //         // ..
      //     });

      signInWithEmailAndPassword(auth, email, password)
        .then(async (abc) => {
          // Signed in
          const q = query(
            collection(db, "superadmins"),
            where("email", "==", email)
          );

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("super", doc.data());
            setUser({
              userSignedIn: true,
              role: "superadmin",
              userInfo: { email, password, role: "superadmin" },
            });
            localStorage.setItem(
              "flightUser",
              JSON.stringify({
                userInfo: { email, password, role: "superadmin" },
              })
            );
            // navigate("/");
          });
          const q0 = query(
            collection(db, "admins"),
            where("email", "==", email)
          );
          const querySnapshot0 = await getDocs(q0);
          querySnapshot0.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("admins", doc.data());
            setUser({
              userSignedIn: true,
              role: "admin",
              userInfo: { email, password, role: "admin" },
            });
            localStorage.setItem(
              "flightUser",
              JSON.stringify({ userInfo: { email, password, role: "admin" } })
            );
            // navigate("/");
          });
          const q1 = query(
            collection(db, "pilots"),
            where("email", "==", email)
          );
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("pilots", doc.data());
            let pilot = doc.data();
            if (!pilot.accountStatus) {
              alert("You cannot login");
            } else {
              setUser({
                userSignedIn: true,
                role: "pilot",
                userInfo: { email, password, role: "pilot" },
              });
              localStorage.setItem(
                "flightUser",
                JSON.stringify({ userInfo: { email, password, role: "pilot" } })
              );
              navigate("/");
            }
          });
          const q2 = query(
            collection(db, "cabinCrews"),
            where("email", "==", email)
          );
          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach((doc) => {
            console.log("cabin", doc.data());
            let cabin = doc.data();
            // doc.data() is never undefined for query doc snapshots
            if (!cabin.accountStatus) {
              alert("you cannot login");
            } else {
              setUser({
                userSignedIn: true,
                role: "cabinCrew",
                userInfo: { email, password, role: "cabinCrew" },
              });
              localStorage.setItem(
                "flightUser",
                JSON.stringify({
                  userInfo: { email, password, role: "cabinCrew" },
                })
              );
              navigate("/");
            }
          });
          const q3 = query(
            collection(db, "techRecords"),
            where("email", "==", email)
          );
          const querySnapshot3 = await getDocs(q3);
          querySnapshot3.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log("tech reco", doc.data());
            setUser({
              userSignedIn: true,
              role: "techRecord",
              userInfo: { email, password, role: "techRecord" },
            });
            localStorage.setItem(
              "flightUser",
              JSON.stringify({
                userInfo: { email, password, role: "techRecord" },
              })
            );
            // navigate("/");
          });
        })
        .catch((error) => {
          console.log(error);
          setOpen(true);
        });
    } else {
      alert("Wrong Credentials");
      setUser({ userSignedIn: false, role: "", userInfo: null });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="signup flex w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-20 flex justify-self-center">
                  <img src={Dreamer} alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-10 h-full bg-gray-200 relative">
                  <h3>Welcome to Flight Link Portal</h3>
                  <ValidatorForm className="form" onSubmit={handleFormSubmit}>
                    <TextValidator
                      style={{ marginBottom: 20, marginTop: 20 }}
                      className="w-80"
                      variant="outlined"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                    <TextValidator
                      className="w-80"
                      label="Password"
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      type={passVisibility ? "text" : "password"}
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <div className="mt-5">
                      <Switch
                        aria-label="show password"
                        onChange={() => setPassVisibility(!passVisibility)}
                        value={passVisibility}
                      />
                      Show Password
                    </div>
                    <div className="flex flex-middle mt-5">
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          type="submit"
                        >
                          Sign in to Enter Dashboard
                        </Button>
                        {loading && (
                          <CircularProgress
                            size={24}
                            // className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Login Error!
        </Alert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
