import { createContext, useState } from "react";

export const DrawerContext = createContext();

export const DrawerProvider = (props) => {
    // const userLoggedIn = localStorage.getItem("user");
    const [open, setOpen] = useState(true);

    return (
        <DrawerContext.Provider value={{ open, setOpen }}>
            {props.children}
        </DrawerContext.Provider>
    );
};
