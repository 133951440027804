import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDJZqA6F2WCZJFIkk1bnUaiB6h5rNjeEFY",
    authDomain: "flightlink-portal.firebaseapp.com",
    projectId: "flightlink-portal",
    storageBucket: "flightlink-portal.appspot.com",
    messagingSenderId: "144002391917",
    appId: "1:144002391917:web:e2de12dc512e340a04c464",
    measurementId: "G-2YV3P5LPQP"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();
export const storage = getStorage(app);
export default firebase;
