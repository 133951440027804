import React, { useEffect, useState } from "react";
import {Button as AntButton, Form, Input, Upload } from "antd";
import { Button, CircularProgress } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import "../styles/feedbackForm.css";
import firebase, { db, storage as stor} from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@mui/icons-material";
import { useLocation } from "react-router";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const AddEngineer = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [componentSize, setComponentSize] = useState("default");
  const [customId, setCustomId] = useState(uuidv4());
  const navigate = useNavigate();
  const [signature, setSignature] = useState("");
  const [loading1, setLoading1] = useState(false);

  const onFormLayoutChange = ({ size }) => setComponentSize(size);

  const { state } = useLocation();
  const { engineer } = state;

  useEffect(() => {
    if (engineer) {
      setName(engineer.name);
      setSignature(engineer.signature);
    }
  }, [engineer, state]);

  const addAdmin = async () => {
    setLoading(true);
    if (name && signature) {
      if (engineer) {
        await db
          .collection("engineers")
          .doc(engineer.id)
          .update({
            name,
            signature,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then(async () => {
            setLoading(false);
            navigate("/engineers");
          });
        setLoading(false);
        navigate("/engineers").catch((error) => {
          alert("Error adding engineer");
          setLoading(false);
        });
      } else {
        await db
          .collection("engineers").doc(customId)
          .set({
            name,
            signature,
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            id: customId,
          })
          .then(async (docRef) => {
            
            setLoading(false);
            navigate("/engineers");
          })
          .catch((error) => {
            alert("Error adding engineer");
            setLoading(false);
          });
      }
    } else {
      alert("Please fill all the fields!");
      setLoading(false);
    }
    setName("");
  };

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
  };

  const handleUpload = (file, name) => {
    setLoading1(true);
    const storageRef = ref(
      stor,
      `/engineers/${customId}/${name}_${customId}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      () => {
        // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setSignature(url);
          console.log(url);
          setLoading1(false);
        });
      }
    );
  };

  return (
    <div className="container">
      <div className="m-4 text-2xl">{engineer ? "Update" : "Add"} Engineer</div>
      {loading && <CircularProgress className="align-middle" />}

      {!loading && (
        <div className="feedback_form">
          <Form
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 40,
            }}
            layout="vertical"
            initialValues={{
              size: componentSize,
            }}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
          >
            <Form.Item label="Name">
              <Input
                placeholder="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>

            <div className="flex flex-row mb-3">
              {loading1 && <CircularProgress className="align-middle" />}
              <div>
                <div className="my-4 text-white">Signature</div>

                <Upload
                  beforeUpload={(e) => {
                    handleUpload(e, "signature");
                    return false
                  }}
                  {...props}
                >
                  <AntButton type="primary" icon={<UploadOutlined />}>
                    Click to Upload
                  </AntButton>
                </Upload>
              </div>
            </div>
          </Form>
          <Button
            className="my-12"
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={addAdmin}
          >
            {engineer ? "Update" : "Submit"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddEngineer;
