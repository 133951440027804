import {styled} from "@mui/material/styles";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    color: 'white',
    justifyContent: 'flex-end',
}));

export default DrawerHeader;
