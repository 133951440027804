import React, {useEffect, useState} from "react";
import {Form, Input} from "antd";
import {Button, CircularProgress} from "@material-ui/core";

import '../styles/feedbackForm.css'
import firebase, {db} from "../utils/firebase";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

const AddAirport = () => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false)
    const [componentSize, setComponentSize] = useState("default");
    const navigate = useNavigate();

    const onFormLayoutChange = ({ size }) => setComponentSize(size);

    const {state} = useLocation();
    const { airport } = state;

    useEffect(() => {
        if (airport) {
            setName(airport.name);
        }
    }, [airport, state])

    const addAdmin = async () => {
        setLoading(true)
        if (name) {
            if (airport) {
                await db.collection("airports").doc(airport.id).update({
                    name,
                    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                    .then(async () => {
                        setLoading(false)
                        navigate('/airports')
                    })
                setLoading(false)
                navigate('/airports')
                    .catch((error) => {
                        alert("Error adding aircraft Type");
                        setLoading(false)
                    });
            } else {
                await db.collection("airports").add({
                    name,
                    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                    .then(async (docRef) => {
                        await db.collection("airports").doc(docRef.id).update({
                            id: docRef.id,
                        })
                        setLoading(false)
                        navigate('/airports')
                    })
                    .catch((error) => {
                        alert("Error adding aircraft type");
                        setLoading(false)
                    });
            }
        }
        else {
            alert('Please fill all the fields!')
            setLoading(false)
        }
        setName('')
    }

    return (
        <div className="container">
            <div className='m-4 text-2xl'>{airport ? 'Update' : 'Add'} Airport</div>
            {loading && <CircularProgress className='align-middle' />}

            {!loading && <div className="feedback_form">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 40,
                    }}
                    layout="vertical"
                    initialValues={{
                        size: componentSize,
                    }}
                    onValuesChange={onFormLayoutChange}
                    size={componentSize}
                >
                    <Form.Item label="Name">
                        <Input
                            placeholder="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                </Form>
                <Button className='my-12' disabled={loading} variant="contained" color='secondary'
                        onClick={addAdmin}>
                    {airport ? 'Update' : 'Submit'}
                </Button>
            </div>}
        </div>
    )
}

export default AddAirport