import React, {useCallback, useEffect, useState} from "react";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {db} from "../utils/firebase";
import AdminRow from "../components/Admins/AdminRow";
import {collection, getDocs, deleteDoc, doc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {Modal} from "antd";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {Snackbar} from "@mui/material";
import MuiAlert  from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Admins = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [agent, setAgent] = useState();
    const navigate = useNavigate();
    const [open ,setOpen] = useState(false)
    const [isError ,setIsError] = useState(false)

    const fetchAgents = useCallback(async () => {
        setLoading(true);
        setData([]);
        let admins = []
        const querySnapshot = await getDocs(collection(db, "admins"));
        querySnapshot.forEach((doc) => {
            admins.push(doc.data())
        });
        setData(admins)
        setLoading(false);

    }, []);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const editAdmin = (admin) => {
        navigate('/createAdmin', { state: { admin } });
    }

    const handleOk = async () => {
            await deleteDoc(doc(db, "admins", agent.id));
            setIsModalVisible(false)
            await fetchAgents();
    }

    const deleteAgent = async (agent) => {
        setAgent(agent)
        setIsModalVisible(true)
    }

    const resetPassword = (agent) => {
        setLoading(true)
        const auth  = getAuth()
        sendPasswordResetEmail(auth,
            agent.email)
            .then(function() {
                setOpen(true)
            })
            .catch(function() {
                setIsError(true)
                setOpen(true)
            });
        setLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
        setIsError(true)
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {loading && <CircularProgress className='align-middle' />}
                {!loading &&
                    <>
                        <div className='m-4 text-2xl'>Admins Table</div>
                        {!loading && data.length > 0 &&
                            <Table className="crud-table" style={{alignSelf: 'center', justifySelf: 'center', width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data
                                        .map((agent, index) => (
                                            <AdminRow i={index} resetPassword={resetPassword} editAdmin={editAdmin} deleteAgent={deleteAgent} agent={agent}/>
                                        ))}
                                </TableBody>
                            </Table>}
                    </>
                }
            </div>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Modal title="Confirm Delete" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(!isModalVisible)}>
                Are you sure you want to delete {agent?.name}?
            </Modal>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {isError ? 'Login Error!' : 'Reset password email sent.'}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Admins;
