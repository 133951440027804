import React, {useEffect, useState} from "react";
import {DatePicker, Form, Input, InputNumber, TimePicker} from "antd";
import {Button, CircularProgress} from "@material-ui/core";

import '../styles/feedbackForm.css'
import firebase, {db} from "../utils/firebase";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import moment from "moment";
import {collection, getDocs} from "firebase/firestore";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import '../styles/AddAircraftLog.css'
const enginePosition = {
    startITT: 0,
    torque: 0,
    NP: 0,
    NH: 0,
    NL: 0,
    T6: 0,
    fuelFlow: 0,
    oilTemperature: 0,
    oilPressure: 0,
    generatorLoad: 0,
    volts: 0,
    hydraulicPressure: 0,
    bleeds: 'Opened',
}

const defaultLogRow = {
    toAirport: '',
    blockTime: {
        chocksOff: 0,
        chocksOn: 0,
        hours: 0,
    },
    flightTime: {
        takeOff: 0,
        landingTime: 0,
        hours: 0,
    },
    engineCycles: {
        leftHand: 1,
        rightHand: 1,
    },
    passengers: 0,
    fuelUplift: 0,
    engineOil: {
        engine1: 1,
        engine2: 2,
    },
    hydraulicUplift: {
        green: 0,
        blue: 0,
    },
    checkHours500: 0,
    checkHours75: 0,
}

const defect = {
    no: 1,
    description: '',
    rectification: '',
}

const format = 'HH:mm';
const AddAircraftLog = () => {
    const [loading, setLoading] = useState(false)
    const [componentSize, setComponentSize] = useState("default");
    const [date, setDate] = useState('');
    const [aircrafts, setAircrafts] = useState([]);
    const [pilots, setPilots] = useState([]);
    const [engineers, setEngineers] = useState([]);
    const [airports, setAirports] = useState([]);
    const [cabinCrews, setCabinCrews] = useState([]);
    const [aircraft, setAircraft] = useState('');
    const [captain, setCaptain] = useState('');
    const [load, setLoad] = useState(true)
    const [coPilot, setCoPilot] = useState('');
    const [engineer, setEngineer] = useState('');
    const [fromAirport, setFromAirport] = useState('');
    const [cabinCrew, setCabinCrew] = useState(['']);
    const [defects, setDefects] = useState([defect])
    const [ias, setIas] = useState(0);
    const [oat, setOat] = useState(0);
    const [alt, setAlt] = useState(0);
    const [currentAircraft, setCurrentAircraft] = useState(null)
    const [differentialPressure, setDifferentialPressure] = useState(0);
    const [base, setBase] = useState('');

    const [logRowsArray, setLogRowsArray] = useState([defaultLogRow])
    const [leftEnginePosistion, setLeftEnginePosition] = useState(enginePosition)
    const [rightEnginePosistion, setRightEnginePosition] = useState(enginePosition)

    const navigate = useNavigate();

    const onFormLayoutChange = ({ size }) => setComponentSize(size);

    const {state} = useLocation();
    const { acLog } = state;

    useEffect(() => {
        (async () => {
            const ac = []
            const p = []
            const ap = []
            const cc = []
            const e = []
            const acSnapshot = await getDocs(collection(db, "aircrafts"));
            acSnapshot.forEach((doc) => {
                ac.push(doc.data())
            });
            const pSnapshot = await getDocs(collection(db, "pilots"));
            pSnapshot.forEach((doc) => {
                p.push(doc.data())
            });
            const apSnapshot = await getDocs(collection(db, "airports"));
            apSnapshot.forEach((doc) => {
                ap.push(doc.data())
            });
            const ccSnapshot = await getDocs(collection(db, "cabinCrews"));
            ccSnapshot.forEach((doc) => {
                cc.push(doc.data())
            });
            const eSnapshot = await getDocs(collection(db, "engineers"));
            eSnapshot.forEach((doc) => {
                e.push(doc.data())
            });
            setAircrafts(ac)
            setAirports(ap)
            setPilots(p)
            setCabinCrews(cc)
            setEngineers(e)
        })()
        if (acLog) {
            setDate(acLog.date);
            setAircraft(acLog.aircraft)
            setIas(acLog.aircraftTrend.IAS)
            setOat(acLog.aircraftTrend.OAT)
            setAlt(acLog.aircraftTrend.ALT)
            setDifferentialPressure(acLog.aircraftTrend.differentialPressure)
            setBase(acLog.base)
            setCaptain(acLog.captain)
            setCoPilot(acLog.coPilot)
            setCabinCrew(acLog.cabinCrew)
            setEngineer(acLog.engineer)
            setFromAirport(acLog.fromAirport)
            setLogRowsArray(acLog.logs)
            setDefects(acLog.defects || [defect])
            setLeftEnginePosition(acLog.aircraftTrend.leftEnginePosistion)
            setRightEnginePosition(acLog.aircraftTrend.rightEnginePosistion)
        }
        setLoad(false)
    }, [acLog, state])

    const addDefect = () => {
        setDefects([...defects, defect]);
    }

    const removeDefect = (i) => {
        const list = [...defects];
        list.splice(i, 1);
        setDefects(list);
    }

    const addCabinCrew = () => {
        setCabinCrew([...cabinCrew, '']);
    }

    const removeCabinCrew = (i) => {
        const list = [...cabinCrew];
        list.splice(i, 1);
        setCabinCrew(list);
    }

    const addAdmin = async () => {
        setLoading(true)
        const a = [];
        const b = [];
        // eslint-disable-next-line array-callback-return
        logRowsArray.map(s => {
            a.push(s['flightTime']['hours'])
            b.push(s['engineCycles']['leftHand'])
            b.push(s['engineCycles']['rightHand'])
        })
        let c = 0.00;
        let c2 = 0;
        b.map(ss => c2 += ss)
        a.map(ss => c += parseFloat(ss))
        const ans = parseFloat(currentAircraft.totalHours) + parseFloat(c)
        const ans1 = parseFloat(currentAircraft.fiveHundredHoursCheck) - parseFloat(c)
        const ans2 = parseFloat(currentAircraft.seventyfiveHoursCheck) - parseFloat(c)
        if (date) {
            if (acLog) {
                await db.collection("aircraftTechnicalLog").doc(acLog.id).update({
                    date,
                    aircraft,
                    base,
                    captain,
                    coPilot,
                    cabinCrew,
                    engineer,
                    fromAirport,
                    logs: logRowsArray,
                    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                    aircraftTrend: {
                        IAS: ias,
                        OAT: oat,
                        ALT: alt,
                        differentialPressure,
                        leftEnginePosistion,
                        rightEnginePosistion,
                    },
                    defects
                })
                    .then(async () => {
                        setLoading(false)
                        navigate('/aircraftLogs')
                    })
                    .catch((error) => {
                        alert("Error adding acLog");
                        setLoading(false)
                    });
            } else {
                await db.collection("aircraftTechnicalLog").add({
                    date,
                    aircraft,
                    base,
                    captain,
                    coPilot,
                    cabinCrew,
                    engineer,
                    fromAirport,
                    logs: logRowsArray,
                    aircraftTrend: {
                        IAS: ias,
                        OAT: oat,
                        ALT: alt,
                        differentialPressure,
                        leftEnginePosistion,
                        rightEnginePosistion,
                    },
                    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
                    defects,
                })
                    .then(async (docRef) => {
                        await db.collection("aircraftTechnicalLog").doc(docRef.id).update({
                            id: docRef.id,
                        })
                        await db.collection("aircrafts").doc(aircraft).update({
                            totalCycles: currentAircraft.totalCycles + c2,
                            totalHours: parseFloat(ans).toFixed(2),
                            fiveHundredHoursCheck: ans1,
                            seventyfiveHoursCheck: ans2,
                        })
                        setLoading(false)
                        navigate('/aircraftLogs')
                    })
                    .catch((error) => {
                        alert("Error adding acLog");
                        setLoading(false)
                    });
                setLoading(false)

            }
        }
        else {
            alert('Please fill all the fields!')
            setLoading(false)
        }
    }

    const handleChangeDefect = (e, i, name) => {
        let list = [...defects];
        list[i][name] = e
        setDefects(list)
    }

    const handleChangeCabinCrew = (e, i) => {
        let list = [...cabinCrew];
        list[i] = e
        setCabinCrew(list)
    }

    const returnTime = (time) => {
        const hrs = time.split(':')[0]
        const mins = time.split(':')[1]

        return parseFloat(`${hrs}.${mins}`).toFixed(2)
    }

    const checkHours = (i) => {
        let list = [...logRowsArray];

        if (list[i]['flightTime']['landingTime'] && list[i]['flightTime']['takeOff']) {
            const landing = returnTime(list[i]['flightTime']['landingTime'])
            const takeOFF = returnTime(list[i]['flightTime']['takeOff'])
            const check500 = (currentAircraft.fiveHundredHoursCheck - (landing -takeOFF).toFixed(2)).toFixed(2)
            const check75 = (currentAircraft.seventyfiveHoursCheck - (landing -takeOFF).toFixed(2)).toFixed(2)
            list[i]['flightTime']['hours'] = (landing - takeOFF).toFixed(2)

            list[i]['checkHours500'] = check500

            list[i]['checkHours75'] = check75
        }
        if (list[i]['blockTime']['chocksOn'] && list[i]['blockTime']['chocksOff']) {
            const chocksOn = returnTime(list[i]['blockTime']['chocksOn'])
            const chocksOff = returnTime(list[i]['blockTime']['chocksOff'])

            list[i]['blockTime']['hours'] = (chocksOn - chocksOff).toFixed(2)
        }

        setLogRowsArray(list)
    }

    const handleChange = (e, i, name) => {
        let list = [...logRowsArray];
        if (name === 'chocksOff' || name === 'chocksOn') {
            list[i]['blockTime'][name] = e;
        } else if (name === 'leftHand' || name === 'rightHand') {
            list[i]['engineCycles'][name] = e;
        } else if (name === 'takeOff' || name === 'landingTime') {
            list[i]['flightTime'][name] = e;
        } else if (name === 'engine1' || name === 'engine2') {
            list[i]['engineOil'][name] = e;
        } else if (name === 'green' || name === 'blue') {
            list[i]['hydraulicUplift'][name] = e;
        } else {
            list[i][name] = e
        }
        setLogRowsArray(list)
    }

    const addInput = (e) => {
        let list = [...logRowsArray];
        list.push({
            toAirport: '',
            blockTime: {
                chocksOff: 0,
                chocksOn: 0,
                hours: 0,
            },
            flightTime: {
                takeOff: 0,
                landingTime: 0,
                hours: 0,
            },
            engineCycles: {
                leftHand: 1,
                rightHand: 1,
            },
            passengers: 0,
            fuelUplift: 0,
            engineOil: {
                engine1: 1,
                engine2: 2,
            },
            hydraulicUplift: {
                green: 0,
                blue: 0,
            },
            checkHours500: 0,
            checkHours75: 0,
        })
        setLogRowsArray(list);
    }

    const removePincode = (index) => {
        const list = [...logRowsArray];
        list.splice(index, 1);
        setLogRowsArray(list);
    }

    if (!load)
    return (
        <div className="container">
            <div className='m-4 text-2xl'>{acLog ? 'Update' : 'Add'} Aircraft Log</div>
            {loading && <CircularProgress className='align-middle' />}

            {!loading && <div style={{width:"130%", overflow:"hidden"}} className="feedback_form w-full">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 40,
                    }}
                    layout="vertical"
                    initialValues={{
                        size: componentSize,
                    }}
                    onValuesChange={onFormLayoutChange}
                    size={componentSize}
                >
                    <Form.Item label="Date">
                            <DatePicker
                                onChange={(e, f) => setDate(f)}
                                defaultValue={date && moment(date, 'YYYY/MM/DD')}
                                style={{height: 30,}}/>
                    </Form.Item>

                    <Form.Item>

                    <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">Aircrafts</InputLabel>
                        <Select
                            sx={{
                                // top:-7,
                              color: "white",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              '.MuiSvgIcon-root ': {
                                fill: "white !important",
                              }
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={aircraft}
                            defaultValue={aircraft}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => {
                                setAircraft(e.target.value)
                                setCurrentAircraft(aircrafts.filter(aa => aa.id === e.target.value)[0])
                            }}
                        >
                            {aircrafts.length > 0 && aircrafts.map(type => <MenuItem value={type.id}>{type.registration}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl style={{width: 200, marginLeft: 100}}>
                        <InputLabel id="demo-simple-select-label">Base</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={base}
                            defaultValue={base}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => setBase(e.target.value)}
                        >
                            <MenuItem value={'DSM'}>DSM</MenuItem>
                            <MenuItem value={'ARK'}>ARK</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl style={{width: 200, marginLeft: 100}}>
                        <InputLabel id="demo-simple-select-label">Captain</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={captain}
                            defaultValue={captain}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => setCaptain(e.target.value)}
                        >
                            {pilots.length > 0 && pilots.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    </Form.Item>

                    <Form.Item>

                        <FormControl style={{width: 200}}>
                            <InputLabel id="demo-simple-select-label">Co-Pilot</InputLabel>
                            <Select
                            sx={{
                                // top:-7,
                              color: "white",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              '.MuiSvgIcon-root ': {
                                fill: "white !important",
                              }
                            }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={coPilot}
                                defaultValue={coPilot}
                                label="Aircraft"
                                style={{color: 'white'}}
                                onChange={(e) => setCoPilot(e.target.value)}
                            >
                                {pilots.length > 0 && pilots.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <FormControl style={{width: 200, marginLeft: 100}}>
                            <InputLabel id="demo-simple-select-label">Engineer</InputLabel>
                            <Select
                            sx={{
                                // top:-7,
                              color: "white",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              '.MuiSvgIcon-root ': {
                                fill: "white !important",
                              }
                            }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={engineer}
                                defaultValue={engineer}
                                label="Aircraft"
                                style={{color: 'white'}}
                                onChange={(e) => setEngineer(e.target.value)}
                            >
                                {engineers.length > 0 && engineers.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Form.Item>

                    {cabinCrew.length > 0 && cabinCrew.map((item, i) => ( <>

                        <FormControl style={{width: 200}}>
                            <InputLabel id="demo-simple-select-label">Cabin Crew</InputLabel>
                            <Select
                            sx={{
                                // top:-7,
                              color: "white",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              '.MuiSvgIcon-root ': {
                                fill: "white !important",
                              }
                            }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={item}
                                defaultValue={item}
                                label="Aircraft"
                                style={{color: 'white'}}
                                onChange={(e) => handleChangeCabinCrew(e.target.value, i)}
                            >
                                {cabinCrews.length > 0 && cabinCrews.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                            </Select>
                        </FormControl>

                        {cabinCrew.length - 1 === i && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => addCabinCrew(e)}
                            >
                                Add
                            </button>
                        )}
                        {cabinCrew.length > 1 && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => removeCabinCrew(i)}
                            >
                                Remove
                            </button>
                        )}
                    </>))}

                    <div className='my-4 text-white text-lg'>From</div>
                    <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">From Airport</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fromAirport}
                            defaultValue={fromAirport}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => setFromAirport(e.target.value)}
                        >
                            {airports.length > 0 && airports.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    {logRowsArray.map((item, i) => ( <div style={{ border: "1px solid white", padding: 20, marginTop: 10, width:"200%"}}>
                        <div className='my-4 text-white text-lg'>To</div>

                        <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">To Airport</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.toAirport}
                        defaultValue={item.toAirport}
                        label="Aircraft"
                        style={{color: 'white'}}
                        onChange={(e) => handleChange(e.target.value, i, 'toAirport')}
                        >
                    {airports.length > 0 && airports.map(type => <MenuItem value={type.id}>{type.name}</MenuItem>)}
                        </Select>
                        </FormControl>

                        <div className='flex'>
                            <div>
                        <div className='my-4 text-white text-lg'>Block Time</div>

                        <div className='flex'>
                        <FormControl style={{width: 200}}>
                        <div className='my-4 text-white'>Chocks Off</div>
                        <TimePicker
                        onChange={(t, ts) => handleChange(ts, i, 'chocksOff')}
                        defaultValue={item.blockTime.chocksOff && moment(item.blockTime.chocksOff, format)}
                        format={format} />;
                        </FormControl>

                        <FormControl style={{width: 200, marginLeft: 50,}}>
                        <div className='my-4 text-white'>Chocks On</div>
                        <TimePicker
                            onChange={(t, ts) => handleChange(ts, i, 'chocksOn')}
                            defaultValue={item.blockTime.chocksOn && moment(item.blockTime.chocksOn, format)}
                        format={format} />;
                        </FormControl>
                        </div>
                            </div>

                            <div className='ml-12'>
                        <div className='my-4 text-white text-lg'>Flight Time</div>

                        <div className='flex'>
                        <FormControl style={{width: 200}}>
                        <div className='my-4 text-white'>Take off</div>
                        <TimePicker
                            onChange={(t, ts) => handleChange(ts, i, 'takeOff')}
                            defaultValue={item.flightTime.takeOff && moment(item.flightTime.takeOff, format)}
                            format={format} />;
                        </FormControl>

                        <FormControl style={{width: 200, marginLeft: 50,}}>
                        <div className='my-4 text-white'>Landing Time</div>
                        <TimePicker
                            onChange={(t, ts) => handleChange(ts, i, 'landingTime')}
                            defaultValue={item.flightTime.landingTime && moment(item.flightTime.landingTime, format)}
                            format={format} />;
                        </FormControl>
                        </div>
                        </div>
                        </div>

                    <div className='flex'><div>
                    <div className='my-4 text-white text-lg'>Engine Cycles</div>

                        <div className='flex'>
                        <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">Left Hand</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.engineCycles.leftHand}
                        defaultValue={item.engineCycles.leftHand}
                        label="Aircraft"
                        style={{color: 'white'}}
                        onChange={(e) => handleChange(e.target.value, i, 'leftHand')}
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        </Select>
                        </FormControl>

                        <FormControl style={{width: 200, marginLeft: 100}}>
                        <InputLabel id="demo-simple-select-label">Right Hand</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.engineCycles.rightHand}
                        defaultValue={item.engineCycles.rightHand}
                        label="Aircraft"
                        style={{color: 'white'}}
                        onChange={(e) => handleChange(e.target.value, i, 'rightHand')}
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        </Select>
                        </FormControl>
                        </div>
                    </div>
                        <div className='ml-12' >
                        <div className='my-4 text-white text-lg'>Engine Oil</div>

                        <div className='flex'>
                            <FormControl style={{width: 200}}>
                                <InputLabel id="demo-simple-select-label">Engine 1</InputLabel>
                                <Select
                                sx={{
                                    // top:-7,
                                  color: "white",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                  }
                                }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={item.engineOil.engine1}
                                    defaultValue={item.engineOil.engine1}
                                    label="Aircraft"
                                    style={{color: 'white'}}
                                    onChange={(e) => handleChange(e.target.value, i, 'engine1')}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl style={{width: 200, marginLeft: 100}}>
                                <InputLabel id="demo-simple-select-label">Engine 2</InputLabel>
                                <Select
                                sx={{
                                    // top:-7,
                                  color: "white",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(228, 219, 233, 0.25)",
                                  },
                                  '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                  }
                                }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={item.engineOil.engine2}
                                    defaultValue={item.engineOil.engine2}
                                    label="Aircraft"
                                    style={{color: 'white'}}
                                    onChange={(e) => handleChange(e.target.value, i, 'engine2')}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    </div>
                        <div className='flex'>

                        <div className='flex mt-8'>
                            <Form.Item style={{width: 300,}} label="Passengers">
                                <InputNumber
                                    placeholder="passengers"
                                    value={item.passengers}
                                    onChange={(e) => handleChange(e, i, 'passengers')}
                                />
                            </Form.Item>

                            <Form.Item style={{marginLeft: 50, width: 300,}} label="Fuel Uplift">
                                <InputNumber
                                    placeholder="fuel uplift"
                                    value={item.fuelUplift}
                                    onChange={(e) => handleChange(e, i, 'fuelUplift')}
                                />
                            </Form.Item>
                        </div>
                            <div className='ml-12'>

                        <div className='my-4 text-white text-lg'>Hydraulic Uplift</div>

                        <div className='flex mt-4'>
                        <Form.Item style={{width: 300,}} label="Green">
                        <InputNumber
                        placeholder="green"
                        value={item.hydraulicUplift.green}
                        onChange={(e) => handleChange(e, i, 'green')}
                        />
                        </Form.Item>

                        <Form.Item style={{marginLeft: 50, width: 300,}} label="Blue">
                        <InputNumber
                        placeholder="blue"
                        value={item.hydraulicUplift.blue}
                        onChange={(e) => handleChange(e, i, 'blue')}
                        />
                        </Form.Item>
                        </div>
                        </div>
                        </div>

                        <div className='flex-col'>
                            <div className='mb-4 text-white text-lg'>Check Remaining Hours</div>

                            <div className='flex mt-4'>
                                <Form.Item style={{width: 300,}} label="500 Hours">
                                    <InputNumber
                                        placeholder="hrs"
                                        disabled
                                        value={item.checkHours500}
                                        onChange={(e) => handleChange(e, i, 'checkHours500')}
                                    />
                                </Form.Item>

                                <Form.Item style={{marginLeft: 50, width: 300,}} label="75 Hours">
                                    <InputNumber
                                        disabled
                                        placeholder="hrs"
                                        value={item.checkHours75}
                                        onChange={(e) => handleChange(e, i, 'checkHours75')}
                                    />
                                </Form.Item>
                                <button
                                    className="pincode_butns"
                                    style={{ height: 30, }}
                                    onClick={(e) => checkHours(i)}
                                >
                                    Check
                                </button>
                            </div>
                        </div>

                        {logRowsArray.length - 1 === i && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => addInput(e)}
                            >
                                Add
                            </button>
                        )}
                        {logRowsArray.length > 1 && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => removePincode(i)}
                            >
                                Remove
                            </button>
                        )}
                        </div>))}

                    <div className='my-4 text-white text-lg'>Aircraft Trend</div>

                    <div className='flex'>
                        <Form.Item style={{ width: 500, }} label={'IAS(Knots)'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={ias}
                                onChange={(e) => setIas(e)}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 500, marginLeft: 50, }} label={'OAT(degree celsius)'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={oat}
                                onChange={(e) => setOat(e)}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex'>
                        <Form.Item style={{ width: 500, }} label={'ALT(Feet)'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={oat}
                                onChange={(e) => setAlt(e)}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 500, marginLeft: 50,}} label={'Differential Pressure'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={differentialPressure}
                                onChange={(e) => setDifferentialPressure(e)}
                            />
                        </Form.Item>
                    </div>
                    <div className='mb-4 text-white text-lg'>Left Hand Engine Position</div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Start IIT'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.startITT}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, startITT: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Torque'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.torque}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, torque: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'NP'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.NP}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, NP: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'NH'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.NH}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, NH: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'NL'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.NL}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, NL: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'T6'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.T6}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, T6: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Fuel Flow'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.fuelFlow}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, fuelFlow: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Oil Temperature'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.oilTemperature}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, oilTemperature: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Oil Pressure'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.oilPressure}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, oilPressure: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Generator Load'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.generatorLoad}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, generatorLoad: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Volts'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.volts}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, volts: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Hydraulic Pressure'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={leftEnginePosistion.hydraulicPressure}
                                onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, hydraulicPressure: e})}
                            />
                        </Form.Item>
                    </div>

                    <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">Bleeds</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leftEnginePosistion.bleeds}
                            defaultValue={leftEnginePosistion.bleeds}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => setLeftEnginePosition({...leftEnginePosistion, bleeds: e.target.value})}
                        >
                            <MenuItem value={'Opened'}>Opened</MenuItem>
                            <MenuItem value={'Closed'}>Closed</MenuItem>
                        </Select>
                    </FormControl>

                    <div className='mt-8 my-4 text-white text-lg'>Right Hand Engine Position</div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Start IIT'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.startITT}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, startITT: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Torque'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.torque}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, torque: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'NP'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.NP}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, NP: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'NH'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.NH}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, NH: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'NL'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.NL}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, NL: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'T6'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.T6}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, T6: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Fuel Flow'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.fuelFlow}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, fuelFlow: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Oil Temperature'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.oilTemperature}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, oilTemperature: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Oil Pressure'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.oilPressure}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, oilPressure: e})}
                            />
                        </Form.Item>
                    </div>

                    <div className='flex justify-between'>
                        <Form.Item style={{ width: 300, }} label={'Generator Load'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.generatorLoad}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, generatorLoad: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Volts'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.volts}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, volts: e})}
                            />
                        </Form.Item>
                        <Form.Item style={{ width: 300, }} label={'Hydraulic Pressure'}>
                            <InputNumber
                                precision={1}
                                step={0.1}
                                value={rightEnginePosistion.hydraulicPressure}
                                onChange={(e) => setRightEnginePosition({...rightEnginePosistion, hydraulicPressure: e})}
                            />
                        </Form.Item>
                    </div>

                    <FormControl style={{width: 200}}>
                        <InputLabel id="demo-simple-select-label">Bleeds</InputLabel>
                        <Select
                        sx={{
                            // top:-7,
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(228, 219, 233, 0.25)",
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          }
                        }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rightEnginePosistion.bleeds}
                            defaultValue={rightEnginePosistion.bleeds}
                            label="Aircraft"
                            style={{color: 'white'}}
                            onChange={(e) => setRightEnginePosition({...rightEnginePosistion, bleeds: e.target.value})}
                        >
                            <MenuItem value={'Opened'}>Opened</MenuItem>
                            <MenuItem value={'Closed'}>Closed</MenuItem>
                        </Select>
                    </FormControl>


                    <div className='my-4 text-white text-lg'>Defects</div>

                    {defects.length > 0 && defects.map((item, i) => ( <div style={{ border: '1px solid white', padding: 20, }}>

                        <Form.Item label="Description">
                            <Input
                                placeholder="description"
                                value={item.description}
                                onChange={(e) => handleChangeDefect(e.target.value, i, 'description')}
                            />
                        </Form.Item>

                        <Form.Item label="Rectification">
                            <Input
                                placeholder="rectification"
                                value={item.rectification}
                                onChange={(e) => handleChangeDefect(e.target.value, i, 'rectification')}
                            />
                        </Form.Item>

                        {defects.length - 1 === i && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => addDefect(e)}
                            >
                                Add
                            </button>
                        )}
                        {defects.length > 1 && (
                            <button
                                className="pincode_butns"
                                onClick={(e) => removeDefect(i)}
                            >
                                Remove
                            </button>
                        )}
                    </div>))}
                </Form>
                <Button style={{ marginTop: 20,} } disabled={loading} variant="contained" color='secondary'
                        onClick={addAdmin}>
                    {acLog ? 'Update' : 'Submit'}
                </Button>
            </div>}
        </div>
    )
    else return null
}

export default AddAircraftLog