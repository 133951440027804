import React, {useEffect, useState} from "react";
import {Form, Input} from "antd";
import {Button as MUIButton, CircularProgress} from "@material-ui/core";

import '../styles/feedbackForm.css'
import {createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail} from "firebase/auth";
import {collection, getDocs, query, where} from "firebase/firestore";
import firebase, {db} from "../utils/firebase";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

const AddTechRecord = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [componentSize, setComponentSize] = useState("default");
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const onFormLayoutChange = ({ size }) => setComponentSize(size);

    const {state} = useLocation();
    const { techRecord } = state;

    useEffect(() => {
        if (techRecord) {
            setName(techRecord.name);
            setEmail(techRecord.email)
            setIsDisabled(true);
        }
    }, [techRecord, state])


    const resetPassword = () => {
        setLoading(true)
        const auth  = getAuth()
        sendPasswordResetEmail(auth,
            email)
            .then(function() {
                alert('Password reset email sent')
            })
            .catch(function(error) {
                alert('Error while sending mail')
            });
        setLoading(false)
    }

    const addAdmin = async () => {
        setLoading(true)

        if (techRecord) {
            await db.collection("techRecords").doc(techRecord.id).update({
                name: name,
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            })
                .then(async () => {
                    setLoading(false)
                    navigate('/techRecords')
                })
                .catch(() => {
                    alert("Error adding techRecord");
                    setLoading(false)
                });
        } else {
            let arr = []
            const q = query(collection(db, "techRecords"), where("email", "==", email));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                arr.push(doc.data())
            });

            if (email && password && name) {
                if (arr.length > 0) {
                    alert('techRecord with this email already exists!')
                    setLoading(false)

                } else {
                    const auth = getAuth();
                    createUserWithEmailAndPassword(auth, email, password)
                        .then(async (user) => {
                            // Signed in
                            await db.collection("techRecords").doc(user.user.uid).set({
                                email,
                                role: 'techRecord',
                                name: name,
                                id: user.user.uid,
                                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                            })
                                .then(() => {
                                    setLoading(false)
                                    navigate('/techRecords')

                                })
                                .catch((error) => {
                                    alert("Error adding techRecord");
                                    setLoading(false)

                                });
                            // ...
                        })
                        .catch((error) => {
                            alert("Error adding techRecord");
                            setLoading(false)

                        });
                }
            }
            else {
                alert('Please fill all the fields!')
                setLoading(false)

            }
        }
    }

    return (
        <div className="container">
            <div className='m-4 text-2xl'>{techRecord ? 'Update' : 'Add'} TechRecord</div>
            {loading && <CircularProgress className='align-middle' />}

            {!loading && <div className="feedback_form">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 40,
                    }}
                    layout="vertical"
                    initialValues={{
                        size: componentSize,
                    }}
                    onValuesChange={onFormLayoutChange}
                    size={componentSize}
                    onSubmitCapture={addAdmin}
                >
                    <Form.Item label="Name">
                        <Input
                            placeholder="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label="Email Address">
                        <Input
                            placeholder="email"
                            value={email}
                            disabled={isDisabled}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>

                    {!isDisabled && <Form.Item label="Password">
                        <Input
                            placeholder="password"
                            value={password}
                            disabled={isDisabled}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>}

                </Form>
                <div className='flex mt-8'>
                    <MUIButton disabled={loading} variant="contained" color='secondary' onClick={addAdmin}>
                        {techRecord ? 'Update' : 'Submit'}
                    </MUIButton>
                    {techRecord && <MUIButton style={{marginLeft: 20,}} disabled={loading} variant="contained" color='secondary'
                                onClick={resetPassword}>
                        Reset Password
                    </MUIButton>}
                </div>
            </div>}
        </div>
    )
}

export default AddTechRecord