import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, InputNumber, Upload } from "antd";
import { Button as MUIButton, CircularProgress } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../styles/feedbackForm.css";
import firebase, { db, storage as stor } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@mui/icons-material";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";

const storage = firebase.storage();

const pilotDefault = {
  ground: "",
  groundParkingCharts: "",
  departure: "",
  arrival: "",
  landing: "",
};

const AddApprochPlate = () => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [downloadUrls, setDownloadUrls] = useState(pilotDefault);
  const [airport, setAirport] = useState("");
  const [airports, setAirports] = useState([]);
  const [allUploaded, setAllUploaded] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [customId, setCustomId] = useState(uuidv4());
  const [imageLoading, setImageLoading] = useState(false);
  const [name, setName] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState({
    ground: null,
    groundParkingCharts: null,
    departure: null,
    arrival: null,
    landing: null,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const { plates } = state;

  useEffect(() => {
    (async function () {
      setLoading(true);
      let airport = [];
      const querySnapshot = await getDocs(collection(db, "airports"));
      querySnapshot.forEach((doc) => {
        airport.push(doc.data().name);
      });
      setAirports(airport);
      setLoading(false);
    })();
    if (plates) {
      setName(plates.name);
      setAirport(plates.airportName);

      setDownloadUrls({
        groundParkingCharts: plates.documents.filter(
          (p) => p.name === "groundParkingCharts"
        )[0].url,
        departure: plates.documents.filter((p) => p.name === "departure")[0]
          .url,
        arrival: plates.documents.filter((p) => p.name === "arrival")[0].url,
        landing: plates.documents.filter((p) => p.name === "landing")[0].url,
        ground: plates.documents.filter((p) => p.name === "ground")[0].url,
      });
    }
    setLoad(false);
  }, [plates, state]);

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
  };

  const handleUpload = (file, name) => {
    setImageLoading(true);
    const storageRef = ref(
      stor,
      `/approachPlates/${plates.id}/${name}_${plates.id}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      () => {
        // update progress
      },
      (err) => {
        setImageLoading(false);
        console.log(err);
      },
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadUrls({ ...downloadUrls, [name]: url });
          setImageLoading(false);
        });
      }
    );
  };

  const addAdmin = async () => {
    setLoading(true);
    if (plates == null) {
      if (name) {
        const customId = uuidv4();
        await db.collection("approachPlates").doc(customId).set({
          documents: [],
        });
        Object.entries(uploadedFiles).map(async (val) => {
          if (val[1] != null) {
            const uploadTask = storage
              .ref(`/approachPlates/${customId}/${val[0]}_${customId}`)
              .put(val[1]);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);
              },
              (error) => {
                console.log(error);
              },
              () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async (downloadURL) => {
                    await db
                      .collection("approachPlates")
                      .doc(customId)
                      .update({
                        documents: firebase.firestore.FieldValue.arrayUnion({
                          name: val[0],
                          url: downloadURL,
                        }),
                      });
                  });
              }
            );
          } else {
            await db
              .collection("approachPlates")
              .doc(customId)
              .update({
                documents: firebase.firestore.FieldValue.arrayUnion({
                  name: val[0],
                  url: "",
                }),
              });
          }
        });

        await db
          .collection("approachPlates")
          .doc(customId)
          .update({
            name: name,
            id: customId,
            airportName: airport,
          })
          .then(async () => {
            setTimeout(() => {
              setLoading(false);
              navigate("/approachPlates");
            }, 5000);
          })
          .catch(() => {
            alert("Error adding aircraft");
            setLoading(false);
          });
      } else {
        alert("Please fill all the fields!");
        setLoading(false);
      }
    } else {
      // if plates exits

      if (name) {
        const documents = [];
        Object.entries(downloadUrls).map((val) =>
          documents.push({
            name: val[0],
            url: val[1],
          })
        );
        await db.collection("approachPlates").doc(plates.id).update({
          documents,
          name: name,
          airportName: airport,
        });
        setLoading(false);
        navigate("/approachPlates");
      } else {
        alert("Please fill all the fields!");
        setLoading(false);
      }
    }
  };

  if (!load)
    return (
      <div className="container">
        <div className="m-4 text-2xl">
          {plates ? "Update" : "Add"} Approch Plates
        </div>
        {loading && <CircularProgress className="align-middle" />}

        {!loading && (
          <div className="feedback_form">
            <Form
              labelCol={{
                span: 7,
              }}
              wrapperCol={{
                span: 40,
              }}
              layout="vertical"
            >
              <div className="flex justify-between">
                <Form.Item style={{width: "70%"}} label="Name">
                  <Input
                    
                    placeholder="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <FormControl style={{ width: 200 }}>
                  <InputLabel sx={{ top: -7 }} id="demo-simple-select-label">
                    Airport Name
                  </InputLabel>
                  <Select
                    sx={{
                      // top:-7,
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "white !important",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={airport}
                    defaultValue={airport}
                    label="Age"
                    style={{
                      color: "white",
                      borderColor: "white",
                      borderWidth: 1,
                    }}
                    onChange={(e) => setAirport(e.target.value)}
                  >
                    {airports.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="my-4 text-white text-xl">Documents</div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">Ground</div>
                  {/* {imageLoading && <CircularProgress className="align-middle" />} */}
                  <Upload
                    name="ground"
                    beforeUpload={(e) => {
                      if (plates) {
                        handleUpload(e, "ground");
                      } else setUploadedFiles({ ...uploadedFiles, ground: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Ground Parking Charts</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (plates) {
                        handleUpload(e, "groundParkingCharts");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        groundParkingCharts: e,
                      });
                      return false;
                    }}
                    name="groundParkingCharts"
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">Departure</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (plates) {
                        handleUpload(e, "departure");
                      }
                      setUploadedFiles({ ...uploadedFiles, departure: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Arrival</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (plates) {
                        handleUpload(e, "arrival");
                      }
                      setUploadedFiles({ ...uploadedFiles, arrival: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Landing</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (plates) {
                        handleUpload(e, "landing");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        landing: e,
                      });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
              </div>
            </Form>
            <div className="flex mt-14 flex-row items-center justify-between">
              {plates && (
                <MUIButton
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  onClick={addAdmin}
                >
                  Update
                </MUIButton>
              )}
              {!plates && (
                <MUIButton
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  onClick={addAdmin}
                >
                  Submit
                </MUIButton>
              )}
            </div>
          </div>
        )}
      </div>
    );
  else return null;
};

export default AddApprochPlate;
