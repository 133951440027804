import React, { useEffect, useState } from "react";
import shortid from "shortid";
import { Button, TableCell, TableRow } from "@material-ui/core";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const AircraftRow = ({
  agent,
  deleteAgent,
  editAircraft,
  resetHoursCheck,
  i,
  viewDocuments,
}) => {
  const [acType, setAcType] = useState("");
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    (async () => {
      const q = query(
        collection(db, "aircraftTypes"),
        where("id", "==", agent.aircraftType)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        setAcType(doc.data().name);
      });
    })();
  }, [agent.aircraftType]);

  useEffect(() => {
    let count = 0;

    agent.documents.map((doc) => {
      console.log(doc);
      if (doc.url != "") {
        count++;
      }
    });
    console.log(count);
    let percent = Math.ceil((count * 100) / 7);
    setPercentage(percent);
  }, []);

  return (
    <TableRow key={shortid.generate()}>
      <TableCell align="left">
        {i + 1}
      </TableCell>
      <TableCell align="left">
        {agent.registration}
      </TableCell>
      <TableCell align="left">
        {agent.totalHours}
      </TableCell>
      <TableCell align="left">
        {agent.totalCycles}
      </TableCell>
      <TableCell
        className="px-0 border-none"
        style={{ width: 400 }}
        align="center"
      >
        <p style={{ width: 80 }}>{agent.fiveHundredHoursCheck}</p>
        {agent.fiveHundredHoursCheck !== 500 && (
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => resetHoursCheck(agent.id, "fiveHundredHoursCheck")}
            color="secondary"
            variant="contained"
          >
            Reset
          </Button>
        )}
      </TableCell>
      <TableCell
        className="px-0 border-none"
        style={{ width: 400 }}
        align="center"
      >
        <p style={{ width: 80 }}>{agent.seventyfiveHoursCheck}</p>
        {agent.seventyfiveHoursCheck !== 75 && (
          <Button
            style={{ marginBottom: 5 }}
            onClick={() => resetHoursCheck(agent.id, "seventyfiveHoursCheck")}
            color="secondary"
            variant="contained"
          >
            Reset
          </Button>
        )}
      </TableCell>
      <TableCell align="left">
        {acType}
      </TableCell>
      <TableCell align="left">
        <div style={{ width: 50, height: 50, marginLeft: 40, marginRight: 40 }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      </TableCell>
      <TableCell className="mx-4 border-none">
        <EditIcon
          style={{ cursor: "pointer" }}
          color="primary"
          className="mx-4"
          onClick={() => editAircraft(agent)}
        />
        <DeleteIcon
          className="m-4"
          color="secondary"
          style={{ cursor: "pointer" }}
          onClick={() => deleteAgent(agent)}
        />
        <Button
          style={{ fontSize: 12, marginLeft: 10, marginTop: 10 }}
          variant="contained"
          color="secondary"
          onClick={() => viewDocuments(agent.documents)}
        >
          View Documents
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AircraftRow;
