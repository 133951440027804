import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, Button, Upload } from "antd";
import { Button as MUIButton, CircularProgress } from "@material-ui/core";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "../styles/feedbackForm.css";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import firebase, { db, storage as stor } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router";
import moment from "moment";
import ProgressBar from "react-bootstrap/ProgressBar";

const storage = firebase.storage();

const ccDefault = {
  passport: "",
  nationalId: "",
  license: "",
  medical: "",
  tcaa: "",

  crmDangerousGoods: "",
  lineTraining: "",
  proficienyCard: "",
};

const AddCabinCrew = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [emergencyName, setEmergencyName] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [emergencyRelation, setEmergencyRelation] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [load, setLoad] = useState(true);
  const [expirationDates, setExpirationDates] = useState(ccDefault);
  const [downloadUrls, setDownloadUrls] = useState(ccDefault);
  const [cabinCrewUid, setCabinCrewUid] = useState("");
  const [cabinCrewCreated, setCabinCrewCreated] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [allUploaded, setAllUploaded] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [secondaryContact, setSecondaryContact] = useState('')

  const [uploadedFiles, setUploadedFiles] = useState({
    passport: null,
    nationalId: null,
    license: null,
    medical: null,
    tcaa: null,

    crmDangerousGoods: null,
    lineTraining: null,
    proficienyCard: null,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const { cabinCrew } = state;

  useEffect(() => {
    if (cabinCrew) {
      setName(cabinCrew.name);
      setEmail(cabinCrew.email);
      setContact(cabinCrew.contact);
      
        setSecondaryContact(cabinCrew.secondaryContact)
      
      setEmergencyRelation(cabinCrew.emergencyContact.relation);
      setEmergencyName(cabinCrew.emergencyContact.name);
      setEmergencyContact(cabinCrew.emergencyContact.contact);
      
      let count = 0;
      console.log(cabinCrew.documents);
      cabinCrew.documents.map((doc) => {
        console.log(doc);
        if (doc.url != "") {
          count++;
        }
      });
      console.log(count);
      let percent = Math.ceil((count * 100) / 8);
      setPercentage(percent);
      setIsActive(cabinCrew.isActive);
      setDownloadUrls({
        passport: cabinCrew.documents.filter((p) => p.name === "passport")[0]
          .url,
        nationalId: cabinCrew.documents.filter(
          (p) => p.name === "nationalId"
        )[0].url,
        license: cabinCrew.documents.filter((p) => p.name === "license")[0].url,
        medical: cabinCrew.documents.filter((p) => p.name === "medical")[0].url,
        tcaa: cabinCrew.documents.filter((p) => p.name === "tcaa")[0].url,

        crmDangerousGoods: cabinCrew.documents.filter(
          (p) => p.name === "crmDangerousGoods"
        )[0].url,
        lineTraining: cabinCrew.documents.filter(
          (p) => p.name === "lineTraining"
        )[0].url,
        proficienyCard: cabinCrew.documents.filter(
          (p) => p.name === "proficienyCard"
        )[0].url,
      });
      setExpirationDates({
        passport: cabinCrew.documents.filter((p) => p.name === "passport")[0]
          .expiryDate,
        nationalId: cabinCrew.documents.filter(
          (p) => p.name === "nationalId"
        )[0].expiryDate,
        license: cabinCrew.documents.filter((p) => p.name === "license")[0]
          .expiryDate,
        medical: cabinCrew.documents.filter((p) => p.name === "medical")[0]
          .expiryDate,
        tcaa: cabinCrew.documents.filter((p) => p.name === "tcaa")[0]
          .expiryDate,

        crmDangerousGoods: cabinCrew.documents.filter(
          (p) => p.name === "crmDangerousGoods"
        )[0].expiryDate,
        lineTraining: cabinCrew.documents.filter(
          (p) => p.name === "lineTraining"
        )[0].expiryDate,
        proficienyCard: cabinCrew.documents.filter(
          (p) => p.name === "proficienyCard"
        )[0].expiryDate,
      });
      setIsDisabled(true);
    }
    setLoad(false);
  }, [cabinCrew, state]);

  const handleUpload = (file, name) => {
    const storageRef = ref(
      stor,
      `/cabinCrews/${cabinCrew.id}/${name}_${cabinCrew.id}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      () => {
        // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadUrls({ ...downloadUrls, [name]: url });
        });
      }
    );
  };

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
  };

  const addAdmin = async () => {
    setLoading(true);

    if (
      name &&
      contact &&
      emergencyName &&
      emergencyRelation &&
      emergencyContact &&
      expirationDates &&
      downloadUrls
    ) {
      const documents = [];
      Object.entries(downloadUrls).map((val) =>
        documents.push({
          name: val[0],
          url: val[1],
          expiryDate: expirationDates[val[0]],
        })
      );
      await db
        .collection("cabinCrews")
        .doc(cabinCrew.id)
        .update({
          name: name,
          contact,
          emergencyName,
          emergencyRelation,
          secondaryContact: secondaryContact ? secondaryContact : "",
          emergencyContact: {
            contact: emergencyContact,
            name: emergencyName,
            relation: emergencyRelation,
          },
          documents,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      setLoading(false);
      navigate("/cabinCrews");
    } else {
      alert("Please fill all the fields!");
      setLoading(false);
    }
  };

  const saveToDatabase = async () => {
    setLoading(true);
    if (!cabinCrewCreated) {
      let arr = [];
      const q = query(
        collection(db, "cabinCrews"),
        where("email", "==", email)
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });

      if (
        email &&
        password &&
        name &&
        contact &&
        emergencyName &&
        emergencyRelation &&
        emergencyContact &&
        expirationDates
      ) {
        if (arr.length > 0) {
          alert("cabinCrew with this email already exists!");
          setLoading(false);
        } else {
          const auth = getAuth();
          createUserWithEmailAndPassword(auth, email, password)
            .then(async (user) => {
              setCabinCrewUid(user.user.uid);
              setCabinCrewCreated(true);
              await db.collection("cabinCrews").doc(user.user.uid).set({
                documents: [],
                isActive: false,
                email,
                  role: "cabinCrew",
                  name: name,
                  contact,
                  emergencyContact: {
                    contact: emergencyContact,
                    name: emergencyName,
                    relation: emergencyRelation,
                  },
                  secondaryContact: secondaryContact ? secondaryContact : "",
                  id: user.user.uid,
                  accountStatus: true,
              });
              // Signed in
              Object.entries(uploadedFiles).map(async (val) => {
                if (val[1] != null) {
                  const uploadTask = storage
                    .ref(
                      `/cabinCrews/${user.user.uid}/${val[0]}_${user.user.uid}`
                    )
                    .put(val[1]);

                  uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log(progress);
                    },
                    (error) => {
                      console.log(error);
                    },
                    () => {
                      uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then(async (downloadURL) => {
                          await db
                            .collection("cabinCrews")
                            .doc(user.user.uid)
                            .update({
                              documents:
                                firebase.firestore.FieldValue.arrayUnion({
                                  name: val[0],
                                  url: downloadURL,
                                  expiryDate: expirationDates[val[0]],
                                }),
                            });
                        });
                    }
                  );
                } else {
                  console.log("Lol", val);
                  await db
                    .collection("cabinCrews")
                    .doc(user.user.uid)
                    .update({
                      documents: firebase.firestore.FieldValue.arrayUnion({
                        name: val[0],
                        url: "",
                        expiryDate: expirationDates[val[0]],
                      }),
                    });
                }
              });

              await db
                .collection("cabinCrews")
                .doc(user.user.uid)
                .update({
                  createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(async () => {
                  setTimeout(async () => {
                    if (
                      Object.values(uploadedFiles).some((file) => file === null)
                    ) {
                      setAllUploaded(false);
                    } else {
                      setAllUploaded(true);
                    }
                    var count = 0;
                    Object.values(uploadedFiles).forEach((file) => {
                      if (file === null) {
                        //
                      } else {
                        count++;
                      }
                    });
                    let percent = Math.ceil((count * 100) / 8);
                    setPercentage(percent);

                    if (Number(count) === 8) {
                      await db
                        .collection("cabinCrews")
                        .doc(user.user.uid)
                        .update({
                          isActive: true,
                        });
                    }
                    setLoading(false);
                  }, 5000);
                })
                .catch(() => {
                  alert("Error adding cabinCrew");
                  setLoading(false);
                });
              // ...
            })
            .catch(() => {
              alert("Error adding cabinCrew");
              setLoading(false);
            });
        }
      } else {
        alert("Please fill all the fields!");
        setLoading(false);
      }
    } else {
      // 2nd time

      await db.collection("cabinCrews").doc(cabinCrewUid).update({
        documents: [],
      });
      // console.log("data", data);
      Object.entries(uploadedFiles).map(async (val) => {
        if (val[1] != null) {
          const uploadTask = storage
            .ref(`/cabinCrews/${cabinCrewUid}/${val[0]}_${cabinCrewUid}`)
            .put(val[1]);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress);
            },
            (error) => {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(async (downloadURL) => {
                  await db
                    .collection("cabinCrews")
                    .doc(cabinCrewUid)
                    .update({
                      documents: firebase.firestore.FieldValue.arrayUnion({
                        name: val[0],
                        url: downloadURL,
                        expiryDate: expirationDates[val[0]],
                      }),
                    });
                });
            }
          );
        } else {
          await db
            .collection("cabinCrews")
            .doc(cabinCrewUid)
            .update({
              documents: firebase.firestore.FieldValue.arrayUnion({
                name: val[0],
                url: "",
                expiryDate: expirationDates[val[0]],
              }),
            });
        }
      });

      await db
        .collection("cabinCrews")
        .doc(cabinCrewUid)
        .update({
          email,
          role: "cabinCrew",
          name: name,
          contact,
          emergencyContact: {
            contact: emergencyContact,
            name: emergencyName,
            relation: emergencyRelation,
          },
          secondaryContact: secondaryContact ? secondaryContact : "",
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(async () => {
          setTimeout(async () => {
            setLoading(false);
            if (Object.values(uploadedFiles).some((file) => file === null)) {
              setAllUploaded(false);
            } else {
              setAllUploaded(true);
            }
            var count = 0;
            Object.values(uploadedFiles).forEach((file) => {
              if (file === null) {
                //
              } else {
                count++;
              }
            });
            let percent = Math.ceil((count * 100) / 8);
            setPercentage(percent);
            if (Number(count) === 8) {
              await db.collection("cabinCrews").doc(cabinCrewUid).update({
                isActive: true,
              });
            }
          }, 5000);
        })
        .catch(() => {
          alert("Error While Adding Document");
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    // console.log(Object.values(uploadedFiles).some((file) => file === null));
    if (cabinCrew) {
      let ref = db.collection("cabinCrews").doc(cabinCrew.id);
      ref.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data.isActive) {
            navigate("/cabinCrews");
          } else {
            alert("First Upload and Save all the documents");
          }
        }
      });
    } else {
      if (
        allUploaded &&
        !Object.values(uploadedFiles).some((file) => file === null)
      ) {
        navigate("/cabinCrews");
      } else {
        alert("First Upload and Save all the documents");
      }
    }
  };

  const handleUpdateToDatabase = async () => {
    setLoading(true);
    if (
      name &&
      contact &&
      emergencyName &&
      emergencyRelation &&
      emergencyContact &&
      expirationDates &&
      downloadUrls
    ) {
      const documents = [];
      // console.log(downloadUrls);

      Object.entries(downloadUrls).map((val) => {
        console.log(expirationDates[val[0]]);
        documents.push({
          name: val[0],
          url: val[1],
          expiryDate: expirationDates[val[0]],
        });
      });
      console.log(documents);
      await db
        .collection("cabinCrews")
        .doc(cabinCrew.id)
        .update({
          name: name,
          contact,
          secondaryContact: secondaryContact ? secondaryContact : "",
          emergencyName,
          emergencyRelation,
          emergencyContact: {
            contact: emergencyContact,
            name: emergencyName,
            relation: emergencyRelation,
          },
          documents,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(async () => {
          setTimeout(async () => {
            let ref = db.collection("cabinCrews").doc(cabinCrew.id);
            ref.get().then(async (doc) => {
              if (doc.exists) {
                let cabinCrew = doc.data();
                

                let count = 0;

                cabinCrew.documents.map((doc) => {
                  console.log(doc);
                  if (doc.url != "") {
                    count++;
                  }
                });
                console.log(count);
                let percent = Math.ceil((count * 100) / 8);
                setPercentage(percent);
                if (Number(count) === 8) {
                  console.log("yo", count);
                  await db.collection("cabinCrews").doc(cabinCrew.id).update({
                    isActive: true,
                  });
                }
                setLoading(false);
              }
            });
          }, 5000);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    } else {
      alert("Please fill all the fields!");
      setLoading(false);
    }
  };

  if (!load)
    return (
      <div className="container">
        <div>
          <ProgressBar now={percentage} label={`${percentage}%`} />
        </div>
        <div className="m-4 text-2xl">
          {cabinCrew ? "Update" : "Add"} CabinCrew
        </div>
        {loading && <CircularProgress className="align-middle" />}

        {!loading && (
          <div className="feedback_form">
            <Form
              labelCol={{
                span: 7,
              }}
              wrapperCol={{
                span: 40,
              }}
              layout="vertical"
            >
              <Form.Item label="Name">
                <Input
                  placeholder="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Email Address">
                <Input
                  placeholder="email"
                  value={email}
                  disabled={isDisabled}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              {!isDisabled && (
                <Form.Item label="Password">
                  <Input
                    placeholder="password"
                    value={password}
                    disabled={isDisabled}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              )}

              <Form.Item label="Contact Number">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="TZ"
                  value={contact}
                  onChange={(contact) => setContact(contact)}
                />
              </Form.Item>
              <Form.Item label="Secondary Contact Number (Optional)">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="TZ"
                  value={secondaryContact}
                  onChange={(secondaryContact) => setSecondaryContact(secondaryContact)}
                />
              </Form.Item>

              <div className="my-4 text-white text-xl">
                Emergency Contact Details
              </div>

              <div className="flex justify-between">
                <Form.Item label="Contact Number">
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="TZ"
                    value={emergencyContact}
                    onChange={(emergencyContact) => setEmergencyContact(emergencyContact)}
                  />
                </Form.Item>

                <Form.Item label="Name">
                  <Input
                    placeholder="name"
                    value={emergencyName}
                    onChange={(e) => setEmergencyName(e.target.value)}
                  />
                </Form.Item>

                <FormControl style={{ width: 200 }}>
                  <InputLabel id="demo-simple-select-label">
                    Relation
                  </InputLabel>
                  <Select
                    sx={{
                      // top:-7,
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "white !important",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={emergencyRelation}
                    defaultValue={emergencyRelation}
                    label="Age"
                    style={{ color: "white" }}
                    onChange={(e) => setEmergencyRelation(e.target.value)}
                  >
                    <MenuItem value={"spouse"}>Spouse</MenuItem>
                    <MenuItem value={"parent"}>Parent</MenuItem>
                    <MenuItem value={"children"}>Children(above 21)</MenuItem>
                    <MenuItem value="relative">Relative</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="my-4 text-white text-xl">Personal Documents</div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">Passport Copy</div>

                  <Upload
                    name="passport"
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "passport");
                      } else
                        setUploadedFiles({ ...uploadedFiles, passport: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">Passport Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.passport &&
                      moment(expirationDates.passport, "YYYY/MM/DD")
                    }
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, passport: f })
                    }
                    style={{ height: 30 }}
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">National ID Copy</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "nationalId");
                      }
                      setUploadedFiles({ ...uploadedFiles, nationalId: e });
                      return false;
                    }}
                    name="nationalId"
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">National ID Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.nationalId &&
                      moment(expirationDates.nationalId, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, nationalId: f })
                    }
                  />
                </div>
              </div>

              <div className="my-4 mt-8 text-white text-xl">
                Personal Licensing
              </div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">License Copy</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "license");
                      }
                      setUploadedFiles({ ...uploadedFiles, license: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">License Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.license &&
                      moment(expirationDates.license, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, license: f })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Medical Copy</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "medical");
                      }
                      setUploadedFiles({ ...uploadedFiles, medical: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">Medical Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.medical &&
                      moment(expirationDates.medical, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, medical: f })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">TCAA ID Copy</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "tcaa");
                      }
                      setUploadedFiles({ ...uploadedFiles, tcaa: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">TCAA ID Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.tcaa &&
                      moment(expirationDates.tcaa, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, tcaa: f })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-0 justify-around">
                <div>
                  {/* <div className="my-4 text-white">
                    Log Book(last 3 pages pdf)
                  </div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "logBook");
                      }
                      setUploadedFiles({ ...uploadedFiles, logBook: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload> */}
                </div>

                {/* <div>
                                <div className='my-4 text-white'>Log Book Expiration</div>

                                <DatePicker defaultValue={expirationDates.logBook && moment(expirationDates.logBook, 'YYYY/MM/DD')} style={{height: 30,}}
                                            onChange={(e, f) => setExpirationDates({...expirationDates, logBook: f})}/>

                            </div> */}
              </div>

              <div className="my-4 mt-8 text-white text-xl">
                Training Records
              </div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">CRM Dangerous Goods</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "crmDangerousGoods");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        crmDangerousGoods: e,
                      });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">CRM Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.crmDangerousGoods &&
                      moment(expirationDates.crmDangerousGoods, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        crmDangerousGoods: f,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Line Training</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "lineTraining");
                      }
                      setUploadedFiles({ ...uploadedFiles, lineTraining: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">
                    Line Training Expiration
                  </div>

                  <DatePicker
                    defaultValue={
                      expirationDates.lineTraining &&
                      moment(expirationDates.lineTraining, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        lineTraining: f,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Proficiency Card</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (cabinCrew) {
                        handleUpload(e, "proficienyCard");
                      }
                      setUploadedFiles({ ...uploadedFiles, proficienyCard: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">Base Check Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.proficienyCard &&
                      moment(expirationDates.proficienyCard, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        proficienyCard: f,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="flex mt-14 flex-row items-center justify-between">
              {cabinCrew && isActive ? (
                <>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={addAdmin}
                  >
                    Update
                  </MUIButton>
                </>
              ) : (
                <>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </MUIButton>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (cabinCrew) {
                        handleUpdateToDatabase();
                      } else {
                        saveToDatabase();
                      }
                    }}
                  >
                    Save
                  </MUIButton>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  else return null;
};

export default AddCabinCrew;
