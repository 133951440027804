import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

import {db} from "../utils/firebase";
import {collection, getDocs, deleteDoc, doc} from "firebase/firestore";
import AircraftRow from "../components/Aircrafts/AircraftRow";
import {Modal} from "antd";
import ViewPilotDocuments from "../components/Pilots/ViewPilotDocuments";

const Aircrafts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [agent, setAgent] = useState();
    const pilotRef = useRef();

    const fetchAgents = useCallback(async () => {
        setLoading(true);
        setData([]);
        let admins = []
        const querySnapshot = await getDocs(collection(db, "aircrafts"));
        querySnapshot.forEach((doc) => {
            admins.push(doc.data())
        });
        setData(admins)
        setLoading(false);

    }, []);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const editAircraft = (aircraft) => {
        navigate('/addAircraft', { state: { aircraft } });
    }

    const deleteAgent = async (agent) => {
        setAgent(agent)
        setIsModalVisible(true)
    }

    const handleOk = async () => {
        await deleteDoc(doc(db, "admins", agent.id));
        setIsModalVisible(false)
        await fetchAgents();
    }


    const resetHoursCheck = async (acId, howMany) => {
        setLoading(true)
        if (howMany === 'fiveHundredHoursCheck') {
            await db.collection("aircrafts").doc(acId).update({
                fiveHundredHoursCheck: 500,
            })
                .then(async () => {
                    setLoading(false)
                })
                .catch(() => {
                    alert(`Error reseting ${howMany}`);
                    setLoading(false)
                });
        } else {
            await db.collection("aircrafts").doc(acId).update({
                seventyfiveHoursCheck: 75,
            })
                .then(async () => {
                    setLoading(false)
                })
                .catch(() => {
                    alert(`Error reseting ${howMany}`);
                    setLoading(false)
                });
        }
        await fetchAgents()
    }

    const viewDocuments = (documents) => {
        pilotRef.current?.open({ documents })
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {loading && <CircularProgress className='align-middle' />}
                {!loading &&
                    <>
                        <div className='m-4 text-2xl'>Aircrafts Table</div>
                        {!loading && data.length > 0 &&
                            <Table className="crud-table" style={{alignSelf: 'center', justifySelf: 'center', width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr</TableCell>
                                        <TableCell>Registration</TableCell>
                                        <TableCell>Total Hours</TableCell>
                                        <TableCell>Total Cycles</TableCell>
                                        <TableCell>500 Hours Check</TableCell>
                                        <TableCell>75 Hours Check</TableCell>
                                        <TableCell>Aircraft Type</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data
                                        .map((agent, index) => (
                                            <AircraftRow
                                                i={index}
                                                viewDocuments={viewDocuments}
                                                resetHoursCheck={resetHoursCheck}
                                                editAircraft={editAircraft}
                                                deleteAgent={deleteAgent} agent={agent}/>
                                        ))}
                                </TableBody>
                            </Table>}
                    </>
                }
            </div>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(!isModalVisible)}>
                Are you sure you want to delete {agent?.registration}?
            </Modal>
            <ViewPilotDocuments ref={pilotRef} />
        </>
    );
}

export default Aircrafts;
