import React, {useCallback, useEffect, useState} from "react";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

import {db} from "../utils/firebase";
import {collection, getDocs, deleteDoc, doc} from "firebase/firestore";
import AircraftLogRow from "../components/AircraftLogs/AircraftLogRow";
import {Modal} from "antd";

const AircraftLogs = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [agent, setAgent] = useState();

    const fetchAgents = useCallback(async () => {
        setLoading(true);
        setData([]);
        let admins = []
        const querySnapshot = await getDocs(collection(db, "aircraftTechnicalLog"));
        querySnapshot.forEach((doc) => {
            admins.push(doc.data())
        });
        setData(admins)
        setLoading(false);

    }, []);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const editAircraft = (acLog) => {
        navigate('/addAircraftLog', { state: { acLog } });
    }

    const handleOk = async () => {
        await deleteDoc(doc(db, "aircraftTechnicalLog", agent.id));
        setIsModalVisible(false)
        await fetchAgents();
    }

    const deleteAgent = async (agent) => {
        setAgent(agent)
        setIsModalVisible(true)
    }

    const viewAircraftLogDetails = (sub) => {
        navigate('/aircraftLogDetail', { state: {log: sub} })
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {loading && <CircularProgress className='align-middle' />}
                {!loading &&
                    <>
                        <div className='m-4 text-2xl'>Aircraft Logs Table</div>
                        {!loading && data.length > 0 &&
                            <Table className="crud-table" style={{alignSelf: 'center', justifySelf: 'center', width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Aircraft</TableCell>
                                        <TableCell>Captain</TableCell>
                                        <TableCell>Co-Pilot</TableCell>
                                        <TableCell>Cabin Crew</TableCell>
                                        <TableCell>Engineer</TableCell>
                                        <TableCell>From Airport</TableCell>
                                        <TableCell>Log Details</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data
                                        .map((agent, index) => (
                                            <AircraftLogRow i={index} viewAircraftLogDetails={viewAircraftLogDetails} editAircraft={editAircraft} deleteAgent={deleteAgent} agent={agent}/>
                                        ))}
                                </TableBody>
                            </Table>}
                    </>
                }
            </div>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Modal title="Confirm Delete" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(!isModalVisible)}>
                Are you sure you want to delete this log?
            </Modal>
        </>
    );
}

export default AircraftLogs;
