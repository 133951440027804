import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./context/userContext";
import AddIcon from "@mui/icons-material/Add";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import BookIcon from "@mui/icons-material/Book";
import { Collapse, makeStyles } from "@material-ui/core";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import { DrawerContext } from "./context/drawerContext";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Logo from "./assets/transparent_logo.webp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ArticleIcon from "@mui/icons-material/Article";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import BadgeIcon from "@mui/icons-material/Badge";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Login from "./Pages/Login";
import "./styles/app.css";
import LoggedInRouter from "./Routes/LoggedInRouter";
import DrawerHeader from "./components/DrawerHeader";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import "antd/dist/antd.min.css";

const drawerWidth = 250;

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#242A45",
    color: "white",
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "fit-content",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

function App() {
  const { user, setUser } = useContext(UserContext);
  const { open, setOpen } = useContext(DrawerContext);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [feedbackCollapse, setFeedbackCollapse] = useState(false);
  const [cabincrew, setCabincrew] = useState(false);
  const [trcollapse, setTrCollapse] = useState(false);
  const [airportCollapse, setAirportCollapse] = useState(false);
  const [aircraftCollapse, setAircraftCollapse] = useState(false);
  const [aircraftLogCollapse, setAircraftLogCollapse] = useState(false);
  const [acCollapse, setACCollapse] = useState(false);
  const [engineerCollapse, setEngineerCollapse] = useState(false);
  const [approchPlatesCollapse, setApprochPlatesCollapse] = useState(false);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleLogout = () => {
    setUser({ userSignedIn: false, role: "", userInfo: null });
    localStorage.removeItem("flightUser");
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("flightUser");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser({
        userSignedIn: true,
        role: foundUser.userInfo.role,
        userInfo: foundUser.userInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <div className="app">
      {user.userSignedIn === true ? (
        <Box sx={{ display: "flex" }}>
          <AppBar
            style={{ background: "white", color: "#1f2a3d" }}
            position="fixed"
            open={open}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                style={{ marginLeft: 20 }}
                noWrap
                component="div"
              >
                Welcome {user.role}
              </Typography>
            </Toolbar>
          </AppBar>
          <CssBaseline />
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            className="drawer"
            classes={{ paper: classes.paper }}
            variant="persistent"
            anchor="left"
            open={open}
            style={{ width: 280 }}
          >
            <DrawerHeader>
              <img src={Logo} className="w-15 h-12" alt="logo" />
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon style={{ fill: "white" }} />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem button>
                <Link to="/" className="flex items-center py-1">
                  <ListItemIcon>
                    <HomeIcon style={{ fill: "white" }} />
                  </ListItemIcon>
                  <ListItemText className="text-white" primary="Home" />
                </Link>
              </ListItem>
              {user.role === "superadmin" && (
                <>
                  <ListItem className="flex flex-col">
                    <ListItemButton onClick={handleOpenSettings}>
                      <ListItemIcon>
                        <SupervisedUserCircleIcon style={{ fill: "white" }} />
                      </ListItemIcon>
                      <ListItemText primary="Admins" className="mr-20" />
                      {openCollapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton>
                          <Link
                            className="flex items-center"
                            to="/createAdmin"
                            state={{ admin: null }}
                          >
                            <AddIcon />
                            <ListItemText
                              className="ml-2 text-white"
                              primary="Add Admin"
                            />
                          </Link>
                        </ListItemButton>
                        <ListItemButton>
                          <Link className="flex items-center" to="/admins">
                            <BackupTableIcon />
                            <ListItemText
                              className="ml-2 text-white"
                              primary="Admins Table"
                            />
                          </Link>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                </>
              )}
              {user.role !== 'cabinCrew' && <ListItem className="flex flex-col">
                <ListItemButton
                  onClick={() => setFeedbackCollapse(!feedbackCollapse)}
                >
                  <ListItemIcon>
                    <BadgeIcon style={{ fill: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Pilots" className="mr-24" />
                  {feedbackCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={feedbackCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      {user.role !== "pilot" && (
                        <Link
                          className="flex items-center"
                          to="/addPilot"
                          state={{ pilot: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Pilot"
                          />
                        </Link>
                      )}
                    </ListItemButton>
                    <ListItemButton>
                      <Link className="flex items-center" to="/pilots">
                        <BackupTableIcon />
                        <ListItemText
                          className="ml-2 text-white"
                          primary="Pilots Table"
                        />
                      </Link>
                    </ListItemButton>
                  </List>
                </Collapse>
              </ListItem>}
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton onClick={() => setCabincrew(!cabincrew)}>
                    <ListItemIcon>
                      <SupportAgentIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Cabin Crew" className="mr-24" />
                    {cabincrew ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={cabincrew} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addcabinCrew"
                          state={{ cabinCrew: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add CC"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/cabinCrews">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="CC Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton onClick={() => setTrCollapse(!trcollapse)}>
                    <ListItemIcon>
                      <ReceiptLongIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Tech Records" className="mr-20" />
                    {trcollapse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={trcollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addTechRecord"
                          state={{ techRecord: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add TR"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/techRecords">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="TR Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton onClick={() => setACCollapse(!acCollapse)}>
                    <ListItemIcon>
                      <ConnectingAirportsIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Aircrafts" className="mr-16" />
                    {acCollapse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={acCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addAircraft"
                          state={{ aircraft: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Aircraft"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/aircrafts">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Aircrafts Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}
              <ListItem className="flex flex-col">
                <ListItemButton
                  onClick={() => setAircraftLogCollapse(!aircraftLogCollapse)}
                >
                  <ListItemIcon>
                    <BookIcon style={{ fill: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Aircraft Technical Log"
                    className="mr-16"
                  />
                  {aircraftLogCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={aircraftLogCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <Link
                        className="flex items-center"
                        to="/addAircraftLog"
                        state={{ acLog: null }}
                      >
                        <AddIcon />
                        <ListItemText
                          className="ml-2 text-white"
                          primary="Add Log"
                        />
                      </Link>
                    </ListItemButton>
                    <ListItemButton>
                      <Link className="flex items-center" to="/aircraftLogs">
                        <BackupTableIcon />
                        <ListItemText
                          className="ml-2 text-white"
                          primary="Logs Table"
                        />
                      </Link>
                    </ListItemButton>
                  </List>
                </Collapse>
              </ListItem>
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton
                    onClick={() => setAircraftCollapse(!aircraftCollapse)}
                  >
                    <ListItemIcon>
                      <LocalAirportIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Aircraft Types" className="mr-20" />
                    {aircraftCollapse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={aircraftCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addAircraftTypes"
                          state={{ aircraft: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Aircraft Types"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/aircraftTypes">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Aircraft Types Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton
                    onClick={() => setAirportCollapse(!airportCollapse)}
                  >
                    <ListItemIcon>
                      <AirplaneTicketIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Airports" className="mr-20" />
                    {airportCollapse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={airportCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addAirport"
                          state={{ airport: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Airport"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/airports">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Airports Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}
              {user.role !== "pilot" && (
                <ListItem className="flex flex-col">
                  <ListItemButton
                    onClick={() => setEngineerCollapse(!engineerCollapse)}
                  >
                    <ListItemIcon>
                      <EngineeringIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Engineers" className="mr-16" />
                    {engineerCollapse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={engineerCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addEngineer"
                          state={{ airport: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Engineer"
                          />
                        </Link>
                      </ListItemButton>
                      <ListItemButton>
                        <Link className="flex items-center" to="/engineers">
                          <BackupTableIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Engineers Table"
                          />
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              )}

              {user.role !== 'cabinCrew' && <ListItem className="flex flex-col">
                <ListItemButton
                  onClick={() =>
                    setApprochPlatesCollapse(!approchPlatesCollapse)
                  }
                >
                  <ListItemIcon>
                    <ArticleIcon style={{ fill: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Approch Plates" className="mr-16" />
                  {approchPlatesCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={approchPlatesCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {user.role !== "pilot" && (
                      <ListItemButton>
                        <Link
                          className="flex items-center"
                          to="/addApprochPlate"
                          state={{ airport: null }}
                        >
                          <AddIcon />
                          <ListItemText
                            className="ml-2 text-white"
                            primary="Add Approch Plate"
                          />
                        </Link>
                      </ListItemButton>
                    )}
                    <ListItemButton>
                      <Link className="flex items-center" to="/approachPlates">
                        <BackupTableIcon />
                        <ListItemText
                          className="ml-2 text-white"
                          primary="Approch Plates Table"
                        />
                      </Link>
                    </ListItemButton>
                  </List>
                </Collapse>
              </ListItem>}

              <ListItem onClick={handleLogout} button>
                <ListItemIcon>
                  <LogoutIcon style={{ fill: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>
          <Main className="main" open={open}>
            <DrawerHeader />
            <LoggedInRouter />
          </Main>
        </Box>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
