import {
    Button, Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import shortid from "shortid";
import {useLocation} from "react-router";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../utils/firebase";
import {useNavigate} from "react-router-dom";

const AircraftLogDetails = React.forwardRef((props, ref) => {

    const [subData, setSubData] = useState();
    const [airports, setAirports] = useState([]);

    const navigate = useNavigate();
    const {state} = useLocation();
    const { log } = state;

    useEffect(() => {
        (async () => {
            let ap = []
            const apSnapshot = await getDocs(collection(db, "airports"));
            apSnapshot.forEach((doc) => {
                ap.push(doc.data())
            });
            setAirports(ap)
        })()
        if (log) setSubData(log)
    }, [log])

    if (subData)
        return(
<>
                    <Table className="crud-table" style={{whiteSpace: "pre", minWidth: "750px", width: '100%'}}>
                        <TableHead>
                            <TableRow style={{ background: '#F1F2F3' }}>
                                <TableCell align='center'>To</TableCell>
                                <TableCell align='center' colSpan={3}>Block Time</TableCell>
                                <TableCell align='center' colSpan={3}>Flight Time</TableCell>
                                <TableCell align='center' colSpan={2}>Engine Cycles</TableCell>
                                <TableCell align='center' colSpan={2}>Engine Oil</TableCell>
                                <TableCell align='center' colSpan={2}>Hydraulic Uplift</TableCell>
                                <TableCell align='center'>Passengers</TableCell>
                                <TableCell align='center'>Fuel Uplift</TableCell>
                                <TableCell align='center'>500 Hours Check</TableCell>
                                <TableCell align='center'>75 Hours Check</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ background: '#F1F2F3' }}>
                                <TableCell></TableCell>
                                <TableCell>Chocks Off</TableCell>
                                <TableCell>Chocks On</TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell>Take Off</TableCell>
                                <TableCell>Landing Time</TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell>Left Hand</TableCell>
                                <TableCell>Right Hand</TableCell>
                                <TableCell>Engine 1</TableCell>
                                <TableCell>Engine 2</TableCell>
                                <TableCell>Green</TableCell>
                                <TableCell>Blue</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {subData.logs.map(s => (
                                <TableRow key={shortid.generate()}>
                                    <TableCell className="px-0" align="left">
                                        {airports.filter(ap => ap.id === s.toAirport)[0]?.name}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.blockTime.chocksOff}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.blockTime.chocksOn}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.blockTime.hours}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.flightTime.takeOff}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.flightTime.landingTime}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.flightTime.hours}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.engineCycles.leftHand}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.engineCycles.rightHand}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.engineOil.engine1}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.engineOil.engine2}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.hydraulicUplift.green}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.hydraulicUplift.blue}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.passengers}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.fuelUplift}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.checkHours500}
                                    </TableCell>
                                    <TableCell className="px-0" align="left">
                                        {s.checkHours75}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
    <div className='my-8 text-lg'>Aircraft Trend</div>

    <Table className="crud-table" style={{whiteSpace: "pre", minWidth: "750px", width: '100%'}}>
        <TableHead c>
            <TableRow style={{ background: '#F1F2F3' }}>
                <TableCell>IAS</TableCell>
                <TableCell>OAT</TableCell>
                <TableCell>ALT</TableCell>
                <TableCell>Differential Pressure</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key={shortid.generate()}>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.IAS}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.OAT}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.ALT}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.differentialPressure}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
    <Table className="crud-table my-12" style={{whiteSpace: "pre", minWidth: "750px", width: '100%'}}>
        <TableHead c>
            <TableRow style={{ background: '#F1F2F3' }}>
                <TableCell>Start ITT</TableCell>
                <TableCell>Torque</TableCell>
                <TableCell>NP</TableCell>
                <TableCell>NH</TableCell>
                <TableCell>NL</TableCell>
                <TableCell>T6</TableCell>
                <TableCell>Fuel Flow</TableCell>
                <TableCell>Oil Temperature</TableCell>
                <TableCell>Oil Pressure</TableCell>
                <TableCell>Generator Load</TableCell>
                <TableCell>Volts</TableCell>
                <TableCell>Hydraulic Pressure</TableCell>
                <TableCell>Bleeds</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key={shortid.generate()}>
                <TableCell className="px-0" align="left">
                    Left Engine Position
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.startITT}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.torque}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.NP}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.NL}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.T6}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.fuelFlow}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.oilTemperature}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.oilPressure}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.generatorLoad}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.volts}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.hydraulicPressure}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.leftEnginePosistion.bleeds}
                </TableCell>
            </TableRow>
            <TableRow key={shortid.generate()}>
                <TableCell className="px-0" align="left">
                    Right Engine Position
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.startITT}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.torque}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.NP}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.NL}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.T6}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.fuelFlow}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.oilTemperature}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.oilPressure}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.generatorLoad}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.volts}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.hydraulicPressure}
                </TableCell>
                <TableCell className="px-0" align="left">
                    {subData.aircraftTrend.rightEnginePosistion.bleeds}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
    <Button onClick={() => navigate('/aircraftLogs')} color='primary'
            variant='contained'>
        Go Back
    </Button>
</>
        )
    else return null;
});

export default AircraftLogDetails;
