import React, { useState, useEffect } from "react";
import shortid from "shortid";
import { Button, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const PlatesRow = ({
  agent,
  deleteAgent,
  editPilot,
  resetPassword,
  i,
  viewDocuments,
}) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    let count = 0;
    
    agent.documents.map((doc) => {
      if (doc.url != "") {
        count++;
      }
    });
    let percent = (count * 100) / 10;
    setPercentage(percent);
  }, []);
  return (
    <TableRow key={shortid.generate()}>
      <TableCell align="left">
        {i + 1}
      </TableCell>
      <TableCell align="left">
        {agent.name}
      </TableCell>
      <TableCell align="left">
        {agent.airportName}
      </TableCell>

      <TableCell className="px-0 border-none">
        <EditIcon
          style={{ cursor: "pointer" }}
          className="mx-4"
          color="primary"
          onClick={() => editPilot(agent)}
        />
        <DeleteIcon
          style={{ cursor: "pointer" }}
          className="m-4"
          color="secondary"
          onClick={() => deleteAgent(agent)}
        />
        <div>
          <Button
            style={{ fontSize: 12 }}
            variant="contained"
            color="secondary"
            onClick={() => viewDocuments(agent.documents)}
          >
            View Documents
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PlatesRow;
