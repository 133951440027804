import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, InputNumber, Upload } from "antd";
import { Button as MUIButton, CircularProgress } from "@material-ui/core";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../styles/feedbackForm.css";
import firebase, { db, storage as stor } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";

const storage = firebase.storage();

const pilotDefault = {
  registration: "",
  airworth: "",
  radioLicence: "",
  insurance: "",
  weightAndBalance: "",
  operatorsLicence: "",
  airServiceLicence: "",
};

const AddAircraft = () => {
  const [registration, setRegistration] = useState("");
  const [totalHours, setTotalHours] = useState(0);
  const [totalCycles, setTotalCycles] = useState(0);
  const [fiveHundredHoursCheck, setFiveHundredHoursCheck] = useState(500);
  const [seventyfiveHoursCheck, setSeventyfiveHoursCheck] = useState(75);
  const [aircraftType, setAircraftType] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [expirationDates, setExpirationDates] = useState(pilotDefault);
  const [downloadUrls, setDownloadUrls] = useState(pilotDefault);
  const [types, setTypes] = useState([]);
  const [allUploaded, setAllUploaded] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [customId, setCustomId] = useState(uuidv4());
  const [isActive, setIsActive] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState({
    registration: null,
    airworth: null,
    radioLicence: null,
    insurance: null,
    weightAndBalance: null,
    operatorsLicence: null,
    airServiceLicence: null,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const { aircraft } = state;

  useEffect(() => {
    (async () => {
      const admins = [];
      const querySnapshot = await getDocs(collection(db, "aircraftTypes"));
      querySnapshot.forEach((doc) => {
        admins.push(doc.data());
      });
      setTypes(admins);
    })();
    if (aircraft) {
      setRegistration(aircraft.registration);
      setTotalHours(aircraft.totalHours);
      setTotalCycles(aircraft.totalCycles);
      setFiveHundredHoursCheck(aircraft.fiveHundredHoursCheck);
      setSeventyfiveHoursCheck(aircraft.seventyfiveHoursCheck);
      setAircraftType(aircraft.aircraftType);
      let count = 0;

      aircraft.documents.map((doc) => {
        console.log(doc);
        if (doc.url != "") {
          count++;
        }
      });

      let percent = Math.ceil((count * 100) / 7);
      setPercentage(percent);
      setIsActive(aircraft.isActive);
      setDownloadUrls({
        registration: aircraft.documents.filter(
          (p) => p.name === "registration"
        )[0].url,
        airworth: aircraft.documents.filter((p) => p.name === "airworth")[0]
          .url,
        radioLicence: aircraft.documents.filter(
          (p) => p.name === "radioLicence"
        )[0].url,
        insurance: aircraft.documents.filter((p) => p.name === "insurance")[0]
          .url,
        weightAndBalance: aircraft.documents.filter(
          (p) => p.name === "weightAndBalance"
        )[0].url,
        operatorsLicence: aircraft.documents.filter(
          (p) => p.name === "operatorsLicence"
        )[0].url,
        airServiceLicence: aircraft.documents.filter(
          (p) => p.name === "airServiceLicence"
        )[0].url,
      });
      setExpirationDates({
        registration: aircraft.documents.filter(
          (p) => p.name === "registration"
        )[0].expiryDate,
        airworth: aircraft.documents.filter((p) => p.name === "airworth")[0]
          .expiryDate,
        radioLicence: aircraft.documents.filter(
          (p) => p.name === "radioLicence"
        )[0].expiryDate,
        insurance: aircraft.documents.filter((p) => p.name === "insurance")[0]
          .expiryDate,
        weightAndBalance: aircraft.documents.filter(
          (p) => p.name === "weightAndBalance"
        )[0].expiryDate,
        operatorsLicence: aircraft.documents.filter(
          (p) => p.name === "operatorsLicence"
        )[0].expiryDate,
        airServiceLicence: aircraft.documents.filter(
          (p) => p.name === "airServiceLicence"
        )[0].expiryDate,
      });
    }
    setLoad(false);
  }, [aircraft, state]);

  const props = {
    headers: {
      authorization: "authorization-text",
    },
    accept: ".pdf",
  };

  const handleUpload = (file, name) => {
    const storageRef = ref(
      stor,
      `/aircrafts/${aircraft.id}/${name}_${aircraft.id}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      () => {
        // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadUrls({ ...downloadUrls, [name]: url });
        });
      }
    );
  };

  const saveToDatabase = async () => {
    setLoading(true);
    if (registration && aircraftType && expirationDates && uploadedFiles) {
      // const customId = uuidv4();
      // Signed in

      await db.collection("aircrafts").doc(customId).set({
        documents: [],
      });

      Object.entries(uploadedFiles).map(async (val) => {
        if (val[1] != null) {
          const uploadTask = storage
            .ref(`/aircrafts/${customId}/${val[0]}_${customId}`)
            .put(val[1]);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress);
            },
            (error) => {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(async (downloadURL) => {
                  await db
                    .collection("aircrafts")
                    .doc(customId)
                    .update({
                      documents: firebase.firestore.FieldValue.arrayUnion({
                        name: val[0],
                        url: downloadURL,
                        expiryDate: expirationDates[val[0]],
                      }),
                    });
                });
            }
          );
        } else {
          await db
            .collection("aircrafts")
            .doc(customId)
            .update({
              documents: firebase.firestore.FieldValue.arrayUnion({
                name: val[0],
                url: "",
                expiryDate: expirationDates[val[0]],
              }),
            });
        }
      });

      await db
        .collection("aircrafts")
        .doc(customId)
        .update({
          registration,
          totalCycles,
          totalHours,
          aircraftType,
          fiveHundredHoursCheck,
          seventyfiveHoursCheck,
          id: customId,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(async () => {
          setTimeout(async () => {
            if (Object.values(uploadedFiles).some((file) => file === null)) {
              setAllUploaded(false);
            } else {
              setAllUploaded(true);
            }
            var count = 0;
            Object.values(uploadedFiles).forEach((file) => {
              if (file === null) {
                //
              } else {
                count++;
              }
            });
            if (Number(count) === 7) {
                 
              await db.collection("aircrafts").doc(customId).update({
                isActive: true,
              });
            }
            
            let percent = Math.ceil((count * 100) / 7);
            setPercentage(percent);
            setLoading(false);
          }, 5000);
        })
        .catch(() => {
          alert("Error adding aircraft");
          setLoading(false);
        });
    } else {
      alert("Please fill all the fields!");
      setLoading(false);
    }
  };

  const addAdmin = async () => {
    setLoading(true);
    if (aircraft == null) {
      //
    } else {
      if (registration && aircraftType && expirationDates && downloadUrls) {
        const documents = [];
        Object.entries(downloadUrls).map((val) =>
          documents.push({
            name: val[0],
            url: val[1],
            expiryDate: expirationDates[val[0]],
          })
        );
        await db
          .collection("aircrafts")
          .doc(aircraft.id)
          .update({
            registration,
            totalCycles,
            totalHours,
            aircraftType,
            fiveHundredHoursCheck,
            seventyfiveHoursCheck,
            documents,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          });
        setLoading(false);
        navigate("/aircrafts");
      } else {
        alert("Please fill all the fields!");
        setLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    console.log(Object.values(uploadedFiles).some((file) => file === null));
    if (aircraft) {
      let ref = db.collection("aircrafts").doc(aircraft.id);
      ref.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data.isActive) {
            navigate("/aircrafts");
          } else {
            
            alert("First Upload and Save all the documents");
          }
        }
      });
    } else {
      if (
        allUploaded &&
        !Object.values(uploadedFiles).some((file) => file === null)
      ) {
        navigate("/aircrafts");
      } else {
        alert("First Upload and Save all the documents");
      }
    }
  };


  const handleUpdateToDatabase = async () => {
    setLoading(true);
    if (
      registration && aircraftType && expirationDates && downloadUrls
    ) {
      const documents = [];
      // console.log(downloadUrls);

      Object.entries(downloadUrls).map((val) => {
        console.log(expirationDates[val[0]]);
        documents.push({
          name: val[0],
          url: val[1],
          expiryDate: expirationDates[val[0]],
        });
      });
      console.log(documents);
      await db
        .collection("aircrafts")
        .doc(aircraft.id)
        .update({
          registration,
          totalCycles,
          totalHours,
          aircraftType,
          fiveHundredHoursCheck,
          seventyfiveHoursCheck,
          documents,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(async () => {
          setTimeout(async () => {
            let ref = db.collection("aircrafts").doc(aircraft.id);
            ref.get().then(async (doc) => {
              if (doc.exists) {
                let aircraft = doc.data();
                
                let count = 0;

                aircraft.documents.map((doc) => {
                  console.log(doc);
                  if (doc.url != "") {
                    count++;
                  }
                });
                console.log(count);
                let percent = Math.ceil((count * 100) / 7);
                setPercentage(percent);
                if (Number(count) === 7) {
                 
                  await db.collection("aircrafts").doc(aircraft.id).update({
                    isActive: true,
                  });
                }
                setLoading(false)
              }
            });
          }, 8000);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    } else {
      alert("Please fill all the fields!");
      setLoading(false);
    }
  };


  if (!load && types)
    return (
      <div className="container">
        <div>
          <ProgressBar now={percentage} label={`${percentage}%`} />
        </div>
        <div className="m-4 text-2xl">
          {aircraft ? "Update" : "Add"} Aircraft
        </div>
        {loading && <CircularProgress className="align-middle" />}

        {!loading && (
          <div className="feedback_form">
            <Form
              labelCol={{
                span: 7,
              }}
              wrapperCol={{
                span: 40,
              }}
              layout="vertical"
            >
              <Form.Item label="Registration">
                <Input
                  placeholder="registration"
                  value={registration}
                  onChange={(e) => setRegistration(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Total Hours">
                <InputNumber
                  precision={2}
                  step={0.1}
                  placeholder="Hours"
                  value={totalHours}
                  onChange={(e) => setTotalHours(e)}
                />
              </Form.Item>

              <Form.Item label="Total Cycles">
                <InputNumber
                  placeholder="Cycles"
                  value={totalCycles}
                  onChange={(e) => setTotalCycles(e)}
                />
              </Form.Item>

              <Form.Item label="500 Hour Cycle">
                <InputNumber
                  precision={2}
                  step={0.1}
                  placeholder=""
                  value={fiveHundredHoursCheck}
                  onChange={(e) => setFiveHundredHoursCheck(e)}
                />
              </Form.Item>

              <Form.Item label="75 Hour Cycle">
                <InputNumber
                  precision={2}
                  step={0.1}
                  placeholder=""
                  value={seventyfiveHoursCheck}
                  onChange={(e) => setSeventyfiveHoursCheck(e)}
                />
              </Form.Item>

              <FormControl style={{ width: 200 }}>
                <InputLabel id="demo-simple-select-label">
                  Aircraft Types
                </InputLabel>
                <Select
                  sx={{
                    // top:-7,
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={aircraftType}
                  defaultValue={aircraftType}
                  label="Aircraft"
                  style={{ color: "white" }}
                  onChange={(e) => setAircraftType(e.target.value)}
                >
                  {types.length > 0 &&
                    types.map((type) => (
                      <MenuItem value={type.id}>{type.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>

              <div className="my-4 text-white text-xl">Documents</div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">
                    Registration Certificate
                  </div>

                  <Upload
                    name="registration"
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "registration");
                      } else
                        setUploadedFiles({ ...uploadedFiles, registration: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">Registration Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.registration &&
                      moment(expirationDates.registration, "YYYY/MM/DD")
                    }
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        registration: f,
                      })
                    }
                    style={{ height: 30 }}
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">AIRWORTH Certificate</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "airworth");
                      }
                      setUploadedFiles({ ...uploadedFiles, airworth: e });
                      return false;
                    }}
                    name="airworth"
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">AIRWORTH Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.airworth &&
                      moment(expirationDates.airworth, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, airworth: f })
                    }
                  />
                </div>
              </div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">Radio Licence</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "radioLicence");
                      }
                      setUploadedFiles({ ...uploadedFiles, radioLicence: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">
                    Radio License Expiration
                  </div>

                  <DatePicker
                    defaultValue={
                      expirationDates.radioLicence &&
                      moment(expirationDates.radioLicence, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        radioLicence: f,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Insurance Certificate</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "insurance");
                      }
                      setUploadedFiles({ ...uploadedFiles, insurance: e });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">Insurance Expiration</div>

                  <DatePicker
                    defaultValue={
                      expirationDates.insurance &&
                      moment(expirationDates.insurance, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({ ...expirationDates, insurance: f })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">
                    Weight & Balance Certificate
                  </div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "weightAndBalance");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        weightAndBalance: e,
                      });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">
                    Weight & Balance Expiration
                  </div>

                  <DatePicker
                    defaultValue={
                      expirationDates.weightAndBalance &&
                      moment(expirationDates.weightAndBalance, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        weightAndBalance: f,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex mt-4 justify-around">
                <div>
                  <div className="my-4 text-white">Operators Licence</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "operatorsLicence");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        operatorsLicence: e,
                      });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">
                    Operators Licence Expiration
                  </div>

                  <DatePicker
                    defaultValue={
                      expirationDates.operatorsLicence &&
                      moment(expirationDates.operatorsLicence, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        operatorsLicence: f,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex justify-around">
                <div>
                  <div className="my-4 text-white">Air Service Licence</div>

                  <Upload
                    beforeUpload={(e) => {
                      if (aircraft) {
                        handleUpload(e, "airServiceLicence");
                      }
                      setUploadedFiles({
                        ...uploadedFiles,
                        airServiceLicence: e,
                      });
                      return false;
                    }}
                    {...props}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </div>

                <div>
                  <div className="my-4 text-white">
                    Air Service Licence Expiration
                  </div>

                  <DatePicker
                    defaultValue={
                      expirationDates.airServiceLicence &&
                      moment(expirationDates.airServiceLicence, "YYYY/MM/DD")
                    }
                    style={{ height: 30 }}
                    onChange={(e, f) =>
                      setExpirationDates({
                        ...expirationDates,
                        airServiceLicence: f,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="flex mt-14 flex-row items-center justify-between">
            {aircraft && isActive ? (
                <>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={addAdmin}
                  >
                    Update
                  </MUIButton>
                  
                </>
              ) : (
                <>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </MUIButton>
                  <MUIButton
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (aircraft) {
                        handleUpdateToDatabase();
                      } else {
                        saveToDatabase();
                      }
                    }}
                  >
                    Save
                  </MUIButton>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  else return null;
};

export default AddAircraft;
