import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  // const userLoggedIn = localStorage.getItem("user");
  const [user, setUser] = useState({
    userSignedIn: false,
    role: '',
    userInfo: null,
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
